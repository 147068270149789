import React, { useState } from 'react';
import {
  Box,
  Tab as MUITab,
  Tabs as MUITabs,
  Paper,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import Loading from 'components/loader/loading';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'services/hook';
import ProductReceiptDetails from './details/productReceiptDetailsPage';
import ProductReceiptPickup from './pickup/productReceiptPickup';
import ProductReceiptInfoTabLayout from './productReceiptInfoTabLayout';
import ProductReceiptWorkflows from './workflows/productReceiptWorkflows';
import { useGetProductReceiptQuery } from 'services/api/productReceiptApi';
import { Button } from 'components/button';
import EndorsementModal from './endorsementModal';
import { OrganizationType } from 'services/enums/organization';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { PermissionType } from 'services/enums/permissions';
import ShowInfo from 'components/info/showInfo';
import ProductReceiptAnalytics from './analytics/productReceiptAnalytics';
import ProductReceiptPayment from 'pages/payment/productReceiptPayment';

const tabList = ['details', 'workflow', 'analytics', 'pickup', 'payments'];
const ProductReceiptInfoPage = () => {
  const { organizationType, organizationConfig } = useAppSelector(
    state => state.auth
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { productReceiptId, tab } = useParams();
  const currentTab = tabList.indexOf(tab ?? 'details');

  const { data } = useGetProductReceiptQuery({
    productReceiptId: productReceiptId ?? ''
  });

  const distributorsName = data?.holders[data?.holders.length - 1]?.name;
  const [endorsementWorkflowModalOpen, setEndorsementWorkflowModalOpen] =
    useState<boolean>(false);
  const { data: permissions } = useGetPermissionsQuery({});

  const handleChange = (newTabIndex: number) => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const basePath = location.pathname.substring(0, lastSlashIndex);
    navigate(`${basePath}/${tabList[newTabIndex]}`);
  };

  const isActiveTab = (tab: string) => {
    return tab === tabList[currentTab];
  };
  return (
    <>
      {data && (
        <Box
          sx={{
            alignItems: 'center',
            padding: '2rem',
            py: 0,
            backgroundColor: '#ffffff',
            width: '100%',
            top: '0',
            left: '0',
            zIndex: 2,
            position: 'absolute'
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '3.125rem',
              display: 'flex',
              alignItems: 'center',
              gap: '.313rem'
            }}
          >
            <IconButton
              size="medium"
              sx={{
                color: '#344054'
              }}
              onClick={() => {
                if (
                  organizationType === OrganizationType.LENDER ||
                  organizationType === OrganizationType.DISTRIBUTOR
                ) {
                  return navigate(
                    `/product-receipts/central-vault/${data.merchant.organization_id}`
                  );
                } else {
                  return navigate(
                    `/product-receipts/central-vault/${data.holders[1].public_id}`
                  );
                }
              }}
            >
              <ArrowBackIosRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.125rem',
                fontWeight: 700,
                lineHeight: '1.35rem',
                letterSpacing: '-0.01rem',
                fontFamily: 'Switzer',
                color: '#475467'
              }}
            >
              {distributorsName}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#98A2B3'
              }}
            >
              /
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.125rem',
                fontWeight: 700,
                lineHeight: '1.35rem',
                letterSpacing: '-0.01rem',
                fontFamily: 'Switzer',
                color: '#98A2B3'
              }}
            >
              {productReceiptId}{' '}
            </Typography>
          </Box>

          <Divider
            sx={{
              color: '#F5F6F7',
              border: '.06rem solid'
            }}
          />

          <MUITabs
            orientation="horizontal"
            value={Math.max(0, currentTab)}
            onChange={(e, value) => handleChange(value)}
            sx={{
              borderBottom: 1,
              borderColor: '#F5F6F7',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Details</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Workflow</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Analytics</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">
                  Product pickup
                </Typography>
              }
            />
            {(organizationType === OrganizationType.DISTRIBUTOR ||
              organizationType === OrganizationType.LENDER) && (
              <MUITab
                label={
                  <Typography variant="bodyMediumMedium">Payments</Typography>
                }
              />
            )}
            {isActiveTab('workflow') && (
              <Box
                sx={{
                  width: 'fit-content',
                  position: 'absolute',
                  zIndex: 1,
                  right: 0,
                  top: '.4rem'
                }}
              >
                {(!organizationConfig.requirePrcEndorsementWorkflow ||
                  data?.endorsement_workflow?.completed) &&
                  (data?.endorsements.length === 0 ||
                    (organizationType !== OrganizationType.MERCHANT &&
                      (data?.endorsements?.length ?? 0) < 2)) && (
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button
                        color="primary"
                        size="sm"
                        iconPosition="start"
                        text={
                          organizationType === OrganizationType.MERCHANT
                            ? 'Issue to Customer'
                            : 'Endorse to Customer'
                        }
                        onClick={() => {
                          if (
                            !permissions ||
                            !permissions.includes(
                              PermissionType.PRODUCT_RECEIPT_COMPLETE_ENDORSEMENT
                            )
                          ) {
                            ShowInfo({
                              message: 'Cannot complete endorsement',
                              subText:
                                'You do not have permissions to do this!',
                              type: 'error',
                              componentType: 'toast',
                              hideProgressBar: false
                            });
                            return;
                          }
                          setEndorsementWorkflowModalOpen(true);
                        }}
                      />
                    </Box>
                  )}
              </Box>
            )}
          </MUITabs>
        </Box>
      )}

      {data ? (
        <Paper
          elevation={0}
          sx={{
            padding: '1.25rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            position: 'relative',
            height: '100%',
            border: '.0625rem solid #F5F6F7',
            mt: 5
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              background: '#fff'
            }}
          >
            <ProductReceiptInfoTabLayout>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('details')}
                id="simple-tabpanel-details"
                aria-labelledby="simple-tab-details"
                sx={{ width: '100%' }}
              >
                <ProductReceiptDetails productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('workflow')}
                id="simple-tabpanel-workflow"
                aria-labelledby="simple-tab-workflow"
                sx={{ width: '100%' }}
              >
                <ProductReceiptWorkflows productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('analytics')}
                sx={{ width: '100%' }}
              >
                <ProductReceiptAnalytics productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('pickup')}
                id="simple-tabpanel-pickup"
                aria-labelledby="simple-tab-pickup"
                sx={{ width: '100%' }}
              >
                <ProductReceiptPickup productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('payments')}
                id="simple-tabpanel-payments"
                aria-labelledby="simple-tab-payments"
                sx={{ width: '100%' }}
              >
                <ProductReceiptPayment />
              </Box>
            </ProductReceiptInfoTabLayout>
          </Box>
        </Paper>
      ) : (
        <Loading loading={true} loadingText="Loading Product Receipt" />
      )}

      <EndorsementModal
        open={endorsementWorkflowModalOpen}
        onClose={() => {
          setEndorsementWorkflowModalOpen(!endorsementWorkflowModalOpen);
        }}
        productReceipt={data}
      />
    </>
  );
};

export default ProductReceiptInfoPage;
