import React, { useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Tabs as MUITabs,
  Tab as MUITab
} from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';
import { Button } from 'components/button';

import AddBranchModal from './branches/addBranchModal';
import BranchesTable from './branches/branchesTable';
import BranchesContextWrapper from './branches/branchesContext';

import CreateTeamModal from './teams/createTeamModal';
import TeamsTable from './teams/teamsTable';
import TeamsContextWrapper from './teams/teamsContext';
import _ from 'lodash';

const tabList = ['Branches', 'Teams'];

const BranchesAndTeamsPage = () => {
  const [createTeamModalOpen, setCreateTeamModalOpen] =
    useState<boolean>(false);
  const [addBranchOpen, setAddBranchOpen] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTabIndex];
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentTabIndex(newTabIndex);
  };

  return (
    <Box pt="3rem">
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack gap=".25rem" width="inherit">
            <Typography
              variant="h6Bold"
              color="#475467"
              sx={{ width: '10.75rem' }}
            >
              Branches & Teams
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: '23rem' }}
            >
              View all your teams and make changes and edits to them
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
            <Button
              color="grey"
              transparent
              size="sm"
              onClick={() => {
                setCreateTeamModalOpen(!createTeamModalOpen);
              }}
              icon={plusIcon}
              iconPosition="start"
              text="New Team"
              styleOverrides={{
                padding: '0.5rem 0.75rem',
                marginRight: '0.5rem'
              }}
            />
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setAddBranchOpen(!addBranchOpen);
              }}
              icon={plusIcon}
              iconPosition="start"
              text="New Branch"
              styleOverrides={{ padding: '0.5rem 0.75rem' }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          pl: '1.25rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '5.3rem',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <MUITabs
          orientation="horizontal"
          value={Math.max(0, currentTabIndex)}
          onChange={(e, value) => handleChange(value)}
          sx={{
            borderBottom: 1,
            borderColor: '#F5F6F7',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {tabList.map(tabId => (
            <MUITab
              key={tabId}
              label={
                <Typography variant="bodyMediumMedium">
                  {_.startCase(tabId)}
                </Typography>
              }
            />
          ))}
        </MUITabs>
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('Branches')}
        sx={{ width: '100%' }}
      >
        <BranchesTable />
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('Teams')}
        sx={{ width: '100%' }}
      >
        <TeamsTable />
      </Box>
      <TeamsContextWrapper setCreateTeamModalOpen={setCreateTeamModalOpen}>
        <CreateTeamModal
          open={createTeamModalOpen}
          onClose={() => {
            setCreateTeamModalOpen(!createTeamModalOpen);
          }}
        />
      </TeamsContextWrapper>
      <BranchesContextWrapper setAddBranchOpen={setAddBranchOpen}>
        <AddBranchModal
          open={addBranchOpen}
          onClose={() => {
            setAddBranchOpen(!addBranchOpen);
          }}
        />
      </BranchesContextWrapper>
    </Box>
  );
};

export default BranchesAndTeamsPage;
