import React from 'react';
import { Box, Typography, Divider, Card, CardContent } from '@mui/material';
import { ReactComponent as VerifiedTick } from 'assets/svg/verifiedTick.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button';

const PaymentSuccessComponent = () => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#ffffff'
      }}
    >
      <Card
        sx={{ width: '100%', maxWidth: '28rem', borderRadius: 2, boxShadow: 3 }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 1.5,
              px: 2,
              py: 2.5,
              borderBottom: '.0625rem solid #F5F6F7'
            }}
          >
            <Box sx={{ pt: '0.2rem' }}>
              <VerifiedTick
                color="success"
                width="1.125rem"
                height="1.125rem"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="bodyLargeSemibold" color="#344054">
                Payment Made Successfully
              </Typography>
              <Typography variant="bodySmallRegular" color="#667085">
                Flux has successfully received your payment. Go back to the
                dashboard to continue.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ p: 2 }}>
            <Typography
              variant="bodyMediumSemibold"
              color="#344054"
              gutterBottom
            >
              PRC Details
            </Typography>
            <Divider sx={{ my: 1, borderColor: '#F2F4F7' }} />

            {[
              { label: 'Product ID', value: '#1267483' },
              { label: 'Merchant Name', value: 'Dangote Limited' },
              { label: 'Product Name', value: 'Cement Bags' },
              { label: 'Quantity', value: '50,000' },
              { label: 'Total Amount Paid', value: '₦200,000,000' }
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 1.2,
                  borderBottom: index < 4 ? '.0625rem solid #F9FAFB' : 'none'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  {item.label}
                </Typography>
                <Typography
                  variant="captionSmall"
                  color="#344054"
                  textTransform="uppercase"
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={{ p: 2, pt: 3 }}>
            <Button
              size="md"
              text="Back to Dashboard"
              color="primary"
              styleOverrides={{
                width: '100%',
                background: 'linear-gradient(180deg, #3E7DF8 0%, #3974E7 100%)',
                borderRadius: 2,
                border: 'none'
              }}
              onClick={onClose}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentSuccessComponent;
