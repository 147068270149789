import React from 'react';
import { Box, Typography, Divider, Stack } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg';
import _ from 'lodash';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import { ProductReceiptRequestInfoApiData } from 'services/types/productReceiptRequest';
import { useAppSelector } from 'services/hook';
import { OrganizationType } from 'services/enums/organization';

interface RequestDetailsTabProps {
  data: ProductReceiptRequestInfoApiData;
}

const RequestDetailsTab: React.FC<RequestDetailsTabProps> = ({ data }) => {
  const productData = data.product_receipt_data?.[0] ?? null;
  const totalQuantity = data.total_request_quantity;
  const productUnit = data.product.unit;

  const tagsArray = productData?.tags ?? [];

  const { organizationType } = useAppSelector(state => state.auth);

  return (
    <Box
      sx={{
        width: '37.3rem',
        maxWidth: '80rem',
        margin: '0 auto',
        borderRadius: '0.75rem',
        backgroundColor: 'white',
        my: '1.75rem',
        boxShadow: '0px 1.5px 4px -1px rgba(113, 120, 132, 0.05)',
        border: '0.0625rem solid rgba(250, 251, 252, 0.99)',
        mb: '5.5rem'
      }}
    >
      {/* Header */}
      <Box sx={{ p: '1rem' }}>
        <Typography variant="bodyLargeSemibold">Primary Details</Typography>
      </Box>

      {/* Divider */}
      <Divider sx={{ color: '#F5F6F7', border: '.06rem solid' }} />

      {/* Tags */}
      {tagsArray.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '0.625rem',
            width: '100%',
            columnGap: '0.9375rem',
            p: '1rem'
          }}
        >
          {tagsArray.map((tag, index) => (
            <Box
              key={index}
              sx={{
                background: '#F5F6F7',
                borderRadius: '0.375rem',
                height: '2.25rem',
                display: 'flex',
                alignItems: 'center',
                p: '0.5rem'
              }}
            >
              <Box mr="0.3125rem" sx={{ display: 'inline' }}>
                <SvgWrapper
                  icon={TagIcon}
                  width=".75rem"
                  height=".75rem"
                  color="#5B5DE3"
                />
              </Box>
              <Typography variant="bodyMediumMedium" color="#5B5DE3">
                {_.capitalize(tag.key)} :
              </Typography>
              <Typography
                variant="bodyMediumMedium"
                color="#5B5DE3"
                ml="0.25rem"
              >
                {_.capitalize(tag.value)}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {/* Info */}
      <Box py="0.5rem" px="1rem">
        <Stack
          direction="row"
          width="inherit"
          justifyContent="space-between"
          sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
        >
          <Typography variant="bodyMediumMedium" color="#667085">
            Date Sent
          </Typography>
          <Typography variant="bodyLargeSemibold" color="#475467">
            {data.requested_on
              ? formattedDate(data.requested_on, 'MMMM Do, YYYY')
              : formattedDate(data.created_on, 'MMMM Do, YYYY')}
          </Typography>
        </Stack>
        {organizationType === OrganizationType.LENDER && (
          <Stack
            direction="row"
            width="inherit"
            justifyContent="space-between"
            sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
          >
            <Typography variant="bodyMediumMedium" color="#667085">
              Status
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.capitalize(data.status)}
            </Typography>
          </Stack>
        )}
        {organizationType === OrganizationType.LENDER && (
          <Stack
            direction="row"
            width="inherit"
            justifyContent="space-between"
            sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
          >
            <Typography variant="bodyMediumMedium" color="#667085">
              Merchant
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.capitalize(data.merchant.name)}
            </Typography>
          </Stack>
        )}
        {organizationType === OrganizationType.MERCHANT && (
          <Stack
            direction="row"
            width="inherit"
            justifyContent="space-between"
            sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
          >
            <Typography variant="bodyMediumMedium" color="#667085">
              Customer
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.capitalize(data.customer.name)}
            </Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          width="inherit"
          justifyContent="space-between"
          sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
        >
          <Typography variant="bodyMediumMedium" color="#667085">
            Product
          </Typography>
          <Typography variant="bodyLargeSemibold" color="#475467">
            {_.capitalize(data.product.name)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          width="inherit"
          justifyContent="space-between"
          sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
        >
          <Typography variant="bodyMediumMedium" color="#667085">
            Total Quantity
          </Typography>
          <Typography variant="bodyLargeSemibold" color="#475467">
            {productData
              ? `${formattedNumber(totalQuantity)} (${productUnit})`
              : 'N/A'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          width="inherit"
          justifyContent="space-between"
          sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
        >
          <Typography variant="bodyMediumMedium" color="#667085">
            Facility
          </Typography>
          <Typography variant="bodyLargeSemibold" color="#475467">
            {_.capitalize(data.facility.facility_type)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          width="inherit"
          justifyContent="space-between"
          sx={{ borderBottom: '.063rem solid #FCFCFD', padding: '1rem 0' }}
        >
          <Typography variant="bodyMediumMedium" color="#667085">
            Repayment Schedule
          </Typography>
          <Typography variant="bodyLargeSemibold" color="#475467">
            {data.facility.repayment_schedule} days
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default RequestDetailsTab;
