import React, { useState } from 'react';
import BarChart from '../../../../components/charts/barchart';
import { Box } from '@mui/material';
import { DateRangeType, Nav } from './chartsNav';
import { useGetClosedProductReceiptsHoldersQuery } from 'services/api/analyticsApi';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

const defaultDateRange = getDateRange('This Month');
const TopDealersChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: productReceiptHoldersData } =
    useGetClosedProductReceiptsHoldersQuery({
      params: {
        start: dateRange.startDate
          ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
          : '2024-01-01',
        ...(dateRange.endDate && {
          end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
        }),
        holder_type: 'distributor',
        limit: '10'
      }
    });

  const labels = productReceiptHoldersData
    ? productReceiptHoldersData.map(item => item.profile.name)
    : [];
  const chartDataset = [
    {
      data: productReceiptHoldersData
        ? productReceiptHoldersData.map(item => Number(item.total_value))
        : [],
      label: 'Top Sales'
    }
  ];

  return (
    <Box
      sx={{
        marginTop: '2rem',
        gap: '1.75rem',
        width: 'inherit',
        height: '25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1.8rem',
        boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
      }}
    >
      <Nav
        header="Top Dealers"
        caption="See the performance of all dealers"
        showButton={true}
        setDateRange={setDateRange}
      />
      <BarChart labels={labels} datasets={chartDataset} stepSize={100000} />
    </Box>
  );
};

export default TopDealersChart;
