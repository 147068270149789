import React from 'react';
import { Box, Typography } from '@mui/material';
import GaugeComponent from 'react-gauge-component';
import { ReactComponent as AiStars } from 'assets/custom-svg/aiStars.svg';
import { SvgWrapper } from 'components/svg';

interface CustomGaugeProps {
  title?: string;
  subtitle?: string;
  gaugeValue?: number;
  gaugeColors?: string[];
  subArcs?: Array<{ limit: number }>;
  // Optional icon: must be a React component that renders an SVG
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyles?: React.CSSProperties;
  ratingLabel?: string;
  ratingSubLabel?: string;
  labelLow?: string;
  labelMedium?: string;
  labelHigh?: string;
}

const CustomGauge: React.FC<CustomGaugeProps> = ({
  title = 'Gauge Title',
  subtitle = 'Gauge Subtitle',
  gaugeValue = 50,
  gaugeColors = ['#fdf0f0', '#fbd17e', '#e8f6f1'],
  subArcs = [{ limit: 30 }, { limit: 70 }, { limit: 100 }],
  icon = AiStars, // pass an imported SVG (ReactComponent) if you want an icon
  iconStyles = {},
  ratingLabel = 'Medium',
  ratingSubLabel = '10% likelihood',
  labelLow = 'Low',
  labelMedium = 'Medium',
  labelHigh = 'High'
}) => {
  return (
    <Box
      sx={{
        borderRadius: '0.5rem',
        border: '0.0625rem solid #F5F6F7',
        background: '#FFF',
        boxShadow:
          '0px 5px 13px -5px rgba(16, 25, 40, 0.05), 0px 2px 4px -1px rgba(16, 25, 40, 0.02)',
        py: '1.25rem',
        px: '1rem'
      }}
    >
      {/* Top Section (Icon + Title + Subtitle) */}
      <Box
        sx={{
          display: 'flex',
          gap: '0.56rem',
          height: '3.75rem'
        }}
      >
        {icon && (
          <SvgWrapper
            icon={icon}
            width="3.25rem"
            height="3.25rem"
            styleOverrides={iconStyles}
          />
        )}
        <Box>
          <Typography
            variant="bodyLargeSemibold"
            color="#344054"
            display={'block'}
          >
            {/* On-time Payment */}
            {title}
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            {/* Probability of timely payments based on past trends. */}
            {subtitle}
          </Typography>
        </Box>
      </Box>

      <GaugeComponent
        type="semicircle"
        arc={{
          colorArray: gaugeColors,
          padding: 0.02,
          subArcs: subArcs
        }}
        pointer={{ type: 'blob', animationDelay: 0 }}
        value={gaugeValue}
        labels={{
          valueLabel: {
            style: {
              fill: '#475467',
              fontWeight: 700,
              fontFamily: 'Switzer, sans-serif'
            }
          }
        }}
      />

      {/* Center Label Below Gauge */}
      <Box textAlign={'center'}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          display={'block'}
        >
          {ratingLabel}
        </Typography>
        <Typography variant="bodyMediumSemibold" color="#475467">
          {ratingSubLabel}
        </Typography>
      </Box>

      {/* Legend */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 1,
          gap: 1,
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: '#fdf0f0',
            borderRadius: '50%'
          }}
        />
        <Typography variant="bodyMediumMedium">{labelLow}</Typography>
        <Box
          sx={{
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: '#fbd17e',
            borderRadius: '50%'
          }}
        />
        <Typography variant="bodyMediumMedium">{labelMedium}</Typography>
        <Box
          sx={{
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: '#e8f6f1',
            borderRadius: '50%'
          }}
        />
        <Typography variant="bodyMediumMedium">
          {/* High */}
          {labelHigh}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomGauge;
