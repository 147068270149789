import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Card } from 'assets/svg/cardFilled.svg';
import { ReactComponent as Menu } from 'assets/svg/menu.svg';
import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Table } from 'components/table';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/terminalEmptyStateIcon.svg';
import { SvgWrapper } from 'components/svg';

interface PaymentHistoryTableData {
  prcCode: string;
  date: string;
  merchant: string;
  product: string;
  totalValue: string;
  receipt?: string;
}

interface PaymentHistoryProps {
  data: PaymentHistoryTableData[];
  isLoading: boolean;
}

const columnHelper = createColumnHelper<PaymentHistoryTableData>();
export const pendingColumns = [
  columnHelper.accessor('prcCode', {
    header: 'Product ID',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('date', {
    header: 'Date Paid',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('merchant', {
    header: 'Merchant',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('product', {
    header: 'Product',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('totalValue', {
    header: 'Amount Paid',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<PaymentHistoryTableData>[];

const PaymentHistoryTable = ({ data, isLoading }: PaymentHistoryProps) => {
  const navigate = useNavigate();
  const pendingPayments = data;

  const handleMakePayment = () => {
    navigate('payment-summary');
  };

  const updatedPendingPaymentsList = useMemo(() => {
    return pendingPayments.map((pendingItem: PaymentHistoryTableData) => ({
      prcCode: pendingItem.prcCode || 'N/A',
      date: pendingItem.date || 'N/A',
      merchant: pendingItem.merchant || 'N/A',
      product: pendingItem.product || 'N/A',
      totalValue: pendingItem.totalValue || '$0'
    }));
  }, [pendingPayments]);

  const menuItems = [
    {
      image: Card,
      displayText: 'Make Payment',
      onClickMenuItem: handleMakePayment,
      fill: '#98A2B3'
    },
    {
      image: Menu,
      displayText: 'View Details',
      onClickMenuItem: (rowData: PaymentHistoryTableData) => {
        navigate(`/product-receipts/${rowData.prcCode}/details`);
      },
      fill: '#98A2B3'
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Table<PaymentHistoryTableData>
        hover
        columns={pendingColumns}
        data={updatedPendingPaymentsList}
        onClickRow={(rowData: PaymentHistoryTableData) =>
          console.log(`Hi from row ${rowData.merchant}`)
        }
        optionsButton
        showMenu
        menuItemProps={menuItems}
        showSearch={false}
        enableFilter={false}
        searchPlaceholderText="Search using product receipt code"
        loadingTable={isLoading}
        title="Payment History"
        subtitle="List of past transactions"
        emptyDataTitle="No Payment History"
        emptyDataSubtitle="You don’t have any payment history yet"
        emptyDataIcon={
          <SvgWrapper icon={EmptyStateIcon} width="9rem" height="9rem" />
        }
      />
    </Box>
  );
};

export default PaymentHistoryTable;
