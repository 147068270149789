import React, { useContext } from 'react';
import { RequestProductReceiptContext } from '../context/requestProductReceiptContext';
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import logo from 'assets/custom-svg/flux-logo.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as Help } from 'assets/svg/helpSolid.svg';

const RequestProductReceiptTopNav = () => {
  const { activeStep, steps } = useContext(RequestProductReceiptContext);
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        borderBottom: '0.063rem solid #F5F6F7'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap=".5rem" alignItems="center">
          <Box
            component="img"
            src={logo}
            sx={{ width: '1.75rem', height: '1.75rem' }}
          />
          <Typography variant="h6Bold" color="#475467">
            Flux
          </Typography>
        </Stack>

        <Stepper
          activeStep={activeStep}
          connector={
            <ChevronRight
              style={{
                stroke: activeStep ? '#3E7DF8' : '#98A2B3',
                width: '1rem',
                height: '1rem'
              }}
            />
          }
          sx={{
            '& .Mui-disabled .MuiStepIcon-root': { display: 'none' },
            '.css-1s42jc7-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
              display: 'none'
            },
            '.css-1s42jc7-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
              width: '1rem',
              color: '#3E7DF8'
            }
          }}
        >
          {steps.map(step => {
            return (
              <Step key={step.label}>
                <Typography variant="bodyMediumMedium">
                  <StepLabel
                    sx={{
                      '.MuiStepLabel-label': {
                        fontWeight: 500,
                        '&.Mui-active': {
                          borderRadius: '0.375rem',
                          background: '#E9F0FE',
                          height: '2.25rem',
                          padding: '0.5rem 0.75rem',
                          color: '#3E7DF8'
                        },
                        '&.Mui-disabled': {
                          color: '#98A2B3'
                        },
                        '&.Mui-completed': {
                          color: '#475467'
                        }
                      }
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Typography>
              </Step>
            );
          })}
        </Stepper>

        <MUIButton
          variant="text"
          startIcon={
            <Help
              style={{
                stroke: '#667085',
                fill: '#fff',
                width: '1rem',
                height: '1rem'
              }}
            />
          }
          sx={{
            border: '.094rem solid #F2F4F7',
            borderRadius: '.5rem',
            padding: '0.5rem .75rem',
            cursor: 'pointer'
          }}
          onClick={() => window.open('https://taficasa.notion.site/', '_blank')}
        >
          <Typography variant="bodyMediumSemibold" color="#667085">
            Help
          </Typography>
        </MUIButton>
      </Stack>
    </Toolbar>
  );
};
export default RequestProductReceiptTopNav;
