import * as yup from 'yup';

export const facilityDetailsValidator = yup.object({
  facility_amount: yup
    .string()
    .matches(/^\d+(,\d{3})*$/, 'Enter a valid amount')
    .required('Facility amount is required'),

  repayment_schedule_value: yup
    .number()
    .typeError('Repayment schedule must be a number')
    .positive('Must be greater than zero')
    .integer('Must be a whole number')
    .required('Repayment schedule value is required'),

  repayment_schedule_unit: yup
    .string()
    .oneOf(['days', 'weeks', 'months'], 'Invalid unit')
    .required('Repayment schedule unit is required'),

  repayment_schedule_days: yup
    .number()
    .positive('Converted days must be greater than zero')
    .integer('Must be a whole number')
    .required('Converted days value is required'),

  guarantee_fee: yup
    .string()
    .matches(/^\d+(,\d{3})*$/, 'Enter a valid amount')
    .required('Guarantee fee is required'),

  subsequent_fee: yup
    .string()
    .matches(/^\d+(,\d{3})*$/, 'Enter a valid amount')
    .required('Subsequent fee is required'),

  tenor: yup.string().required('Tenor is required')
});
