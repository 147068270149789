import React, { useContext } from 'react';
import {
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { Button } from 'components/button';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType
} from '../context/createProductReceiptContext';
import ShowInfo from 'components/info/showInfo';
import {
  ProductReceiptData,
  useCreateProductReceiptMutation
} from 'services/api/productReceiptApi';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import { removeItem } from 'utilities/helperFunc/localStore';

interface BottomNavProps {
  nextButtonLabel?: string;
  onNext?: () => void;
  isNextDisabled?: boolean;
  onBack?: () => void;
}
const CreateProductReceiptBottomNav: React.FC<BottomNavProps> = ({
  nextButtonLabel,
  onNext,
  isNextDisabled,
  onBack
}) => {
  const {
    activeStep,
    data,
    formState,
    steps,
    request,
    handleNext,
    handlePrev,
    selectedType,
    selectedIssueType
  } = useContext(CreateProductReceiptContext);
  const [createProductReceipt, { isLoading }] =
    useCreateProductReceiptMutation();
  const { data: bankData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,lender' }
  });
  const { data: customerData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,end_user' }
  });

  const navigate = useNavigate();

  const bankId = (bankName: string): string => {
    if (bankData) {
      const found = Object.values(bankData).find(
        item => item.name === bankName
      );
      return (found?.organization_id as string) || bankName;
    }
    return bankName;
  };

  const customerId = (customerName: string): string => {
    if (customerData) {
      const found = Object.values(customerData).find(
        item => item.name === customerName
      );
      return (found?.organization_id as string) || customerName;
    }
    return customerName;
  };

  // Default next disabled logic if no prop is passed
  const defaultIsNextDisabled =
    (selectedType === null && activeStep === 0) ||
    (activeStep === 2 && formState) ||
    (selectedIssueType === null && activeStep === 1);

  const disabled =
    isNextDisabled !== undefined ? isNextDisabled : defaultIsNextDisabled;

  // Default onNext: if last step, perform submission; otherwise, advance step
  const handleNextClick = () => {
    if (onNext) {
      onNext();
    } else {
      if (activeStep === steps.length - 1) {
        onSubmit(data);
      } else {
        handleNext();
      }
    }
  };

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      handlePrev();
    }
  };

  // Submission function – note that since our context data is now a single object,
  // we wrap it in an array if your API expects an array
  const onSubmit = async (entry: FormValues) => {
    // Transform tags: map each tag to { key, value }
    const transformedTags = (entry.tags || []).map(tag => ({
      key: tag.tagsType,
      value: tag.tagsName
    }));

    // Ensure quantity and expiry_date are arrays.
    const quantities = Array.isArray(entry.quantity)
      ? entry.quantity
      : [entry.quantity];
    const expiryDates = Array.isArray(entry.expiry_date)
      ? entry.expiry_date
      : [entry.expiry_date];

    const productReceiptData: ProductReceiptData[] = quantities.map(
      (q, index) => ({
        total_quantity: Number(q),
        quoted_price_expiry: expiryDates[index] || '',
        tags: transformedTags
      })
    );

    await createProductReceipt({
      product_variant: entry.product_variant,
      holders: [
        ...(selectedIssueType === ProductReceiptIssueType.INTERMEDIARY
          ? [bankId(entry.bank)]
          : []),
        customerId(entry.customer_name)
      ],
      product_receipt_data: productReceiptData,
      ...(request?.id ? { product_receipt_request: request.id } : {})
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'Product Receipt has been added to your vault',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        removeItem('createProductReceiptFormData');
        removeItem('createProductReceiptStep');
        removeItem('createProductReceiptSelectedType');
        removeItem('createProductReceiptSelectedIssueType');
        navigate('/product-receipts');
      })
      .catch(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'Product Receipt has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        height: '100%',
        borderTop: '0.063rem solid #F5F6F7',
        background: '#fff'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MUIButton
          variant="text"
          startIcon={
            <Avatar
              src={chevronLeft}
              sx={{ width: '1.25rem', height: '1.25rem' }}
            />
          }
          onClick={() => {
            removeItem('createProductReceiptFormData');
            removeItem('createProductReceiptStep');
            removeItem('createProductReceiptSelectedType');
            removeItem('createProductReceiptSelectedIssueType');
            navigate('/product-receipts');
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#344054">
            Exit Product Receipt Creation
          </Typography>
        </MUIButton>

        <Stack direction="row" gap=".75rem">
          {activeStep !== 0 && (
            <Button
              color="grey"
              transparent
              size="md"
              text="Back"
              styleOverrides={{
                width: '10rem',
                padding: '.75rem',
                borderRadius: '.5rem'
              }}
              onClick={handleBackClick}
            />
          )}

          <Button
            color="primary"
            size="md"
            text={
              nextButtonLabel ||
              (activeStep === steps.length - 1
                ? 'Create Product Receipt'
                : activeStep === 2
                  ? 'Review'
                  : 'Next')
            }
            styleOverrides={{
              minWidth: '10rem',
              padding: '.75rem',
              borderRadius: '.5rem'
            }}
            onClick={handleNextClick}
            disabled={disabled}
            loadingIcon={isLoading}
          />
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default CreateProductReceiptBottomNav;
