import React from 'react';
import { Box, Typography, Button as MUIButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DistributionPendingPaymentTable, {
  demoPendingPaymentsData
} from '../../payment/table/distributorsPendingPaymentTable';
import { SearchBar } from 'components/search';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as filterIcon } from 'assets/svg/filter.svg';
import { SvgWrapper } from 'components/svg';

const PendingPaymentsPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            padding: '2rem',
            backgroundColor: '#ffffff',
            width: '100%',
            top: '0',
            left: '0',
            zIndex: 2,
            position: 'absolute'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', rowGap: 1 }}>
              <Box
                onClick={() => navigate('/')}
                sx={{
                  cursor: 'pointer',
                  color: '#667085',
                  padding: '0.5rem',
                  width: '2.25rem',
                  height: '2.25rem'
                }}
              >
                <SvgWrapper
                  icon={ChevronLeft}
                  width="1.5rem"
                  height="1.5rem"
                  styleOverrides={{ fill: 'none', stroke: '#667085' }}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h6Bold"
                  sx={{
                    color: theme => `${theme.palette.common.subheading}`,
                    fontWeight: 600,
                    lineHeight: '1.5rem'
                  }}
                >
                  Pending Payments
                </Typography>

                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    fontWeight: 600,
                    lineHeight: '1.5rem',
                    color: '#667085'
                  }}
                >
                  List of payments that need to be completed.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
              <Box sx={{ width: '18.75rem' }}>
                <SearchBar
                  size="sm"
                  id="tableSearchBar"
                  options={[]}
                  onChange={value => {
                    console.log('value', value);
                  }}
                  placeholderText="Search using product receipt code"
                />
              </Box>
              <MUIButton
                sx={{
                  borderRadius: '0.5rem',
                  border: '0.09375em solid #EAECF0',
                  backgroundColor: '#FFF',
                  color: '#475467',
                  display: 'flex',
                  padding: '0.5rem 0.5rem 0.5rem 0.75rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                  height: '2.25rem',
                  gap: '0.5rem'
                }}
              >
                <SvgWrapper
                  icon={filterIcon}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{ fill: 'none', stroke: '#667085' }}
                />
                Filter
              </MUIButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: {
              md: '2rem',
              xl: '4rem'
            }
          }}
        >
          <DistributionPendingPaymentTable
            data={demoPendingPaymentsData}
            isLoading={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PendingPaymentsPage;
