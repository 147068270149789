import React from 'react';
import { Box, Grid } from '@mui/material';
import RequestProductReceiptTopNav from '../nav/topNav';
import RequestProductReceiptContextWrapper from '../context/requestProductReceiptContext';

const RequestProductReceiptLayout: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return (
    <RequestProductReceiptContextWrapper>
      <Grid
        container
        sx={{ height: 'inherit', overflow: 'hidden', width: 'inherit' }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'sticky',
              width: '100%',
              top: 0,
              zIndex: 2
            }}
          >
            <RequestProductReceiptTopNav />
          </Box>

          <Box
            sx={{
              px: {
                md: '3rem',
                lg: '6rem',
                xl: '10rem'
              },
              paddingY: {
                md: '2rem',
                lg: '2.5rem',
                xl: '5rem'
              },
              height: '100%',
              maxHeight: '93.7vh',
              overflowY: 'auto',
              background: '#F9FAFB'
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </RequestProductReceiptContextWrapper>
  );
};
export default RequestProductReceiptLayout;
