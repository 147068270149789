import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as ChevronRight } from 'assets/custom-svg/tableRightChevron.svg';
import { ReactComponent as ChevronHeaderRight } from 'assets/svg/chevron-right.svg';
import { Button } from 'components/button';
import { Table } from 'components/table';
import { ProductReceiptRequestsApiData } from 'services/types/productReceipt';
import { OrganizationType } from 'services/enums/organization';
import { Chip } from 'components/chip';
import { ReactComponent as Bank } from 'assets/svg/bank.svg';
import { ReactComponent as Flag } from 'assets/svg/flag.svg';
import { TableMenuItem } from 'components/table/subComponents/body/tableMenu';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import { SvgWrapper } from 'components/svg';

import _ from 'lodash';

interface ProductReceiptRequestsTableProps {
  data: ProductReceiptRequestsApiData[];
  organizationType: string;
  isLoading: boolean;
}

const ProductReceiptRequestsTable = ({
  data,
  organizationType,
  isLoading
}: ProductReceiptRequestsTableProps) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<ProductReceiptRequestsApiData>();
  const columns = [
    columnHelper.accessor('product.name', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      enableSorting: false
    }),
    columnHelper.accessor('total_request_price', {
      header: 'TOTAL VALUE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          ₦ {formattedNumber(info.getValue())}
        </Typography>
      ),

      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_request_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {`${Number(info.getValue()).toLocaleString()} (${info.row.original.product.unit})`}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<ProductReceiptRequestsApiData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  const updateColumnDefForOrgs = (organizationType: string) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        0,
        0,
        columnHelper.accessor('customer', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>,
        columnHelper.accessor('lender', {
          id: 'intermediary',
          header: 'INTERMEDIARY',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
      columns.splice(
        5,
        0,
        columnHelper.accessor('is_batch', {
          header: 'TYPE',
          cell: info => (
            <Chip label={info.getValue() ? 'Batch' : 'Single'} size="sm" icon />
          )
        }) as ColumnDef<ProductReceiptRequestsApiData>,
        columnHelper.accessor('status', {
          id: 'status',
          header: 'STATUS',
          cell: info => (
            <Chip
              label={_.capitalize(info.getValue())}
              size="sm"
              icon
              color={info.getValue() === 'approved' ? 'success' : undefined}
            />
          )
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
    } else if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        0,
        0,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>,
        columnHelper.accessor('customer', {
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
      columns.splice(
        5,
        0,
        columnHelper.accessor('status', {
          id: 'status',
          header: 'STATUS',
          cell: info => (
            <Chip
              label={_.capitalize(info.getValue())}
              size="sm"
              icon
              color={info.getValue() === 'approved' ? 'success' : undefined}
            />
          )
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
    }
  };

  updateColumnDefForOrgs(organizationType);

  const getMenuItems = (
    row: ProductReceiptRequestsApiData
  ): TableMenuItem<ProductReceiptRequestsApiData>[] => {
    if (row.status === 'approved') {
      return [
        {
          image: Flag,
          displayText: 'View Details',
          onClickMenuItem: (rowData: ProductReceiptRequestsApiData) => {
            navigate(`/request-sent/${rowData.request_id}/details`);
          },
          fill: '#98A2B3'
        }
      ];
    } else {
      return [
        {
          image: Bank,
          displayText: 'Create Product Receipt',
          onClickMenuItem: () => {
            navigate(`/product-receipts/create?requestId=${row.request_id}`);
          },
          fill: '#98A2B3'
        },
        {
          image: Flag,
          displayText: 'View Details',
          onClickMenuItem: (rowData: ProductReceiptRequestsApiData) => {
            navigate(`/request-sent/${rowData.request_id}/details`);
          },
          fill: '#98A2B3'
        }
      ];
    }
  };

  const handleRowClick = (rowData: ProductReceiptRequestsApiData) => {
    navigate(`/request-sent/${rowData.request_id}/details`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1.25rem"
        spacing={1}
        width="100%"
        border=".063rem solid #F5F6F7"
        borderRadius="0.5rem 0.5rem 0rem 0rem"
        sx={{
          backgroundColor: '#fff'
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 0 0"
        >
          <>
            <Typography variant="bodyMediumSemibold">
              {organizationType === OrganizationType.MERCHANT
                ? 'Requests Received'
                : 'Requests Sent'}
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: 'auto' }}
            >
              {organizationType === OrganizationType.MERCHANT
                ? 'Product receipts requests received from banks'
                : 'Product receipts requests sent to merchants'}
            </Typography>
          </>
        </Stack>
        <Button
          color="grey"
          transparent
          size="sm"
          icon={ChevronHeaderRight}
          iconPosition="end"
          text="See all"
          onClick={() => navigate('/request-sent')}
        />
      </Stack>

      <Table<ProductReceiptRequestsApiData>
        hover
        columns={columns}
        data={data}
        showSearch={false}
        showFooter={false}
        enableFilter={false}
        showMenu={organizationType === OrganizationType.MERCHANT ? true : false}
        optionsButton={
          organizationType === OrganizationType.MERCHANT ? true : false
        }
        menuItemProps={row => getMenuItems(row.original)}
        filterColumns={['']}
        hideCheckboxes={true}
        loadingTable={isLoading}
        tableTitleType="product-receipt-request"
        onClickRow={rowData => handleRowClick(rowData)}
        customOptionsIcon={
          organizationType === OrganizationType.MERCHANT ? undefined : (
            <SvgWrapper
              icon={ChevronRight}
              width="2.25rem"
              height="2.25rem"
              styleOverrides={{ fill: 'none', stroke: '#fff' }}
            />
          )
        }
      />
    </Box>
  );
};

export default ProductReceiptRequestsTable;
