import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as FormIcon } from 'assets/svg/form.svg';
import { ReactComponent as Layers } from 'assets/svg/layers.svg';
import { SvgWrapper } from 'components/svg';
import ShowInfo from 'components/info/showInfo';
import { ProductReceiptType } from 'services/types/productReceipt';
import { CreateProductReceiptContext } from '../context/createProductReceiptContext';
import _ from 'lodash';
import CreateProductReceiptBottomNav from '../nav/bottomNav';

const SelectType = () => {
  const { request, selectedType, setSelectedType } = useContext(
    CreateProductReceiptContext
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '30rem',
        margin: '0 auto',
        marginTop: '1.5rem',
        border: '0.063rem solid #F5F6F7',
        borderRadius: '.75rem',
        background: '#fff'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          padding: '1rem',
          paddingTop: '1.25rem',
          borderBottom: '.063rem solid #F5F6F7'
        }}
      >
        <Typography variant="h7Bold" color="#475467">
          Create Product Receipts
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Create single or batch PRCs
        </Typography>
      </Stack>

      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          padding: '1rem'
        }}
      >
        {productReceiptTypes.map((element, index) => {
          return (
            <Box
              key={index}
              sx={{
                borderRadius: '1rem',
                border:
                  selectedType === element.text
                    ? '.125rem solid #ECF2FE'
                    : '.063rem solid #F5F6F7',
                background: selectedType === element.text ? '#F8FBFE' : '#FFF',
                padding: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '13rem',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (request?.id) {
                  ShowInfo({
                    message: 'This product receipt is created by request',
                    subText: 'You cannot reselect another type',
                    type: 'error',
                    componentType: 'toast',
                    hideProgressBar: true
                  });
                } else {
                  setSelectedType(element.text);
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '2.25rem',
                  width: '2.25rem',
                  borderRadius: '.5rem',
                  backgroundColor:
                    selectedType === element.text ? '#F8FBFE' : '#F5F6F7',
                  padding: '0.5rem',
                  marginBottom: '1.5rem'
                }}
              >
                <SvgWrapper
                  icon={element.image}
                  width="1.25rem"
                  height="1.25rem"
                  color={selectedType === element.text ? '#6597F9' : '#667085'}
                />
              </Box>

              <Stack
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <Typography
                  variant="bodyLargeSemibold"
                  color={selectedType === element.text ? '#344054' : '#667085'}
                >
                  {_.startCase(element.text)}
                </Typography>
                <Typography variant="bodySmallMedium" color="#667085">
                  {element.subtext}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Stack>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <CreateProductReceiptBottomNav
          isNextDisabled={selectedType === null}
          nextButtonLabel="Next"
        />
      </Box>
    </Box>
  );
};

export default SelectType;

const productReceiptTypes = [
  {
    id: 0,
    image: FormIcon,
    text: ProductReceiptType.SINGLE,
    subtext: 'One product receipt for a product'
  },
  {
    id: 1,
    image: Layers,
    text: ProductReceiptType.BATCH,
    subtext: 'Various product receipt in one batch'
  }
];
