import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Table } from 'components/table';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/terminalEmptyStateIcon.svg';
import { SvgWrapper } from 'components/svg';

interface ReceivedPaymentTableData {
  productId: string;
  distributor: string;
  amountPaid: string;
  datePaid: string;
  receipt: string;
}

interface ReceivedPaymentProps {
  data: ReceivedPaymentTableData[];
  isLoading: boolean;
}

export const demoReceivedPaymentsData: ReceivedPaymentTableData[] = [
  {
    productId: 'IND-1456',
    distributor: 'Lafarge Cement PLC',
    amountPaid: '₦3,500,000',
    datePaid: '15th Sep. 2024',
    receipt: '/path-to-receipt-1.pdf'
  },
  {
    productId: 'IND-1789',
    distributor: 'BUA Cement Ltd',
    amountPaid: '₦4,200,000',
    datePaid: '22nd Sep. 2024',
    receipt: '/path-to-receipt-2.pdf'
  },
  {
    productId: 'IND-1987',
    distributor: 'Eagle Cement PLC',
    amountPaid: '₦2,750,000',
    datePaid: '5th Oct. 2024',
    receipt: '/path-to-receipt-3.pdf'
  }
];

const columnHelper = createColumnHelper<ReceivedPaymentTableData>();
export const receivedPaymentColumns = [
  columnHelper.accessor('productId', {
    header: 'PRODUCT ID',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('distributor', {
    header: 'DISTRIBUTOR',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('amountPaid', {
    header: 'AMOUNT PAID',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('datePaid', {
    header: 'DATE PAID',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('receipt', {
    header: 'RECEIPT',
    cell: () => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        style={{ textDecoration: 'underline' }}
      >
        Receipt
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<ReceivedPaymentTableData>[];

const ReceivedPaymentTable = ({ data, isLoading }: ReceivedPaymentProps) => {
  const updatedPendingPaymentsList = useMemo(() => {
    return data.map((paymentItem: ReceivedPaymentTableData) => ({
      productId: paymentItem.productId || 'N/A',
      distributor: paymentItem.distributor || 'N/A',
      amountPaid: paymentItem.amountPaid || '₦0',
      datePaid: paymentItem.datePaid || 'N/A',
      receipt: paymentItem.receipt || '#'
    }));
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#fff',
        marginBottom: '3.125rem'
      }}
    >
      <Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', padding: '1.25rem' }}
        >
          <Typography
            variant="bodyMediumSemibold"
            sx={{
              color: theme => `${theme.palette.common.subheading}`
            }}
          >
            Received Payment
          </Typography>

          <Typography
            variant="bodyMediumMedium"
            sx={{
              color: '#667085'
            }}
          >
            Records of all payments received from distributors
          </Typography>
        </Box>

        <Table<ReceivedPaymentTableData>
          hover
          columns={receivedPaymentColumns}
          data={updatedPendingPaymentsList}
          onClickRow={(rowData: ReceivedPaymentTableData) => {
            console.log(`Hi from row ${rowData.distributor}`);
          }}
          showMenu={false}
          showSearch={true}
          enableFilter={true}
          searchPlaceholderText="Search with product receipt ID and distributor name"
          loadingTable={isLoading}
          emptyDataTitle="No Payment Received"
          emptyDataSubtitle="You haven’t received any payment yet"
          emptyDataIcon={
            <SvgWrapper icon={EmptyStateIcon} width="9rem" height="9rem" />
          }
        />
      </Box>
    </Box>
  );
};

export default ReceivedPaymentTable;
