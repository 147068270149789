import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { Button } from 'components/button';
import { Table } from 'components/table';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { OrganizationType } from 'services/enums/organization';
import { Chip } from 'components/chip';

interface DashboardProductReceiptTableProps {
  data: MiniProductReceiptApiData[];
  organizationType: string;
  isLoading: boolean;
}

const DashboardProductReceiptTable = ({
  data,
  organizationType,
  isLoading
}: DashboardProductReceiptTableProps) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<MiniProductReceiptApiData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC CODE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric',
      enablePinning: true,
      size: 100
    }),
    columnHelper.accessor('product', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue().name}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {`${formattedNumber(info.getValue(), false)} (${info.row.original.product.unit})`}
        </Typography>
      ),
      footer: info => info.column.id,
      enableSorting: false
    }),
    columnHelper.accessor('quoted_price', {
      header: 'TOTAL VALUE',
      cell: info => {
        const quotedPrice = info.getValue();
        const totalQuantity = info.row.original.total_quantity;
        const totalValue = totalQuantity * quotedPrice;

        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {formattedNumber(totalValue, true)}
          </Typography>
        );
      },
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('holders', {
      header: 'ISSUE TYPE',
      cell: info => (
        <Chip
          label={info.getValue().length > 1 ? 'Intermediary' : 'Direct'}
          size="sm"
        />
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<MiniProductReceiptApiData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  const updateColumnDefForOrgs = (organizationType: string) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().organization_name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.DISTRIBUTOR) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().organization_name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    }
  };

  updateColumnDefForOrgs(organizationType);

  const handleRowClick = (rowData: MiniProductReceiptApiData) => {
    navigate(`/product-receipts/${rowData.product_receipt_id}/details`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1.25rem"
        spacing={1}
        width="100%"
        border=".063rem solid #F5F6F7"
        borderRadius="0.5rem 0.5rem 0rem 0rem"
        sx={{
          backgroundColor: '#fff'
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 0 0"
        >
          <>
            <Typography variant="bodyMediumSemibold">
              {organizationType === OrganizationType.MERCHANT
                ? 'New Product Receipts'
                : 'New Product Receipt Received'}
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: 'auto' }}
            >
              {organizationType === OrganizationType.MERCHANT
                ? 'Created recently by your organization'
                : 'Endorsed recently by banks'}
            </Typography>
          </>
        </Stack>
        <Button
          color="grey"
          transparent
          size="sm"
          icon={ChevronRight}
          iconPosition="end"
          text="See all"
          onClick={() => navigate('/product-receipts')}
        />
      </Stack>

      <Table<MiniProductReceiptApiData>
        hover
        columns={columns}
        data={data}
        showSearch={false}
        showFooter={false}
        enableFilter={false}
        filterColumns={['']}
        loadingTable={isLoading}
        onClickRow={rowData => handleRowClick(rowData)}
      />
    </Box>
  );
};

export default DashboardProductReceiptTable;
