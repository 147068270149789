import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

export default function Breadcrumb() {
  const location = useLocation();

  // If we're on a request-sent detail page, hide the breadcrumbs.
  if (location.pathname.startsWith('/request-sent/')) {
    return null;
  }
  const originalCrumbs = location.pathname
    .split('/')
    .filter(crumb => crumb !== '');

  // Only prepend "dashboard" when on the /request-sent route.
  const crumbs =
    location.pathname === '/request-sent'
      ? ['dashboard', ...originalCrumbs]
      : originalCrumbs;

  const crumbName = (element: string) => {
    if (element.startsWith('product-receipts/branch-vault')) {
      return 'Branch Vault';
    } else if (element.startsWith('product-receipts/central-vault')) {
      return 'Central Vault';
    } else if (element.startsWith('branches-teams')) {
      return 'Branches & Teams';
    } else if (element.endsWith('product-receipts')) {
      return 'Vault';
    } else if (element.startsWith('/request-sent')) {
      return 'Request Sent';
    } else return _.startCase(element.replace(/-/g, ' '));
  };

  return (
    <Breadcrumbs
      maxItems={2}
      separator={<span style={{ color: '#fff', fontSize: '1.2rem' }}>/</span>}
      aria-label="breadcrumb"
    >
      {crumbs.map((element, index) => {
        // Determine if we have prepended "dashboard"
        const hasDashboard = crumbs[0] === 'dashboard';

        // If dashboard is present, use '/' for the first crumb and join the rest accordingly.
        const routeTo = hasDashboard
          ? index === 0
            ? '/'
            : `/${crumbs.slice(1, index + 1).join('/')}`
          : `/${crumbs.slice(0, index + 1).join('/')}`;
        if (index > 1) return null;
        return index === 1 ? (
          <Typography key={index} variant="bodyMediumMedium" color="#fff">
            {crumbName(element)}
          </Typography>
        ) : (
          <Link
            style={{
              color: index > 0 ? '#98A2B3' : '#667085',
              textDecoration: 'none'
            }}
            key={index}
            to={routeTo}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              {crumbName(element)}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
