import { Box } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import { removeItem } from 'utilities/helperFunc/localStore';
import FacilityDetails from './steps/facilityDetails';
import ProductReceiptBreakdown from './steps/productReceiptBreakdown';

import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import Analytics from './steps/analytics';
import ProductReceiptDetails from './steps/productReceiptDetails';
import Review from './steps/review';
import { RequestProductReceiptContext } from './context/requestProductReceiptContext';

const RequestProductReceiptPage = () => {
  const { activeStep, steps } = useContext(RequestProductReceiptContext);

  const { data: merchantData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'merchant' }
  });
  const { data: customerData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,end_user' }
  });

  const merchantOptions = useMemo(() => {
    return (
      (merchantData &&
        Object.values(merchantData).map(merchant => ({
          title: merchant.name,
          value: merchant.profile_id
        }))) ||
      []
    );
  }, [merchantData]);

  const customerOptions = useMemo(() => {
    return (
      (customerData &&
        Object.values(customerData).map(customer => ({
          title: customer.name,
          value: customer.profile_id
        }))) ||
      []
    );
  }, [customerData]);

  const isActiveComponent = (el: string) => {
    return el === steps[activeStep]?.label;
  };

  window.onpopstate = () => {
    removeItem('createProductReceiptFormData');
    removeItem('createProductReceiptStep');
    removeItem('createProductReceiptSelectedType');
    removeItem('createProductReceiptSelectedIssueType');
  };

  return (
    <>
      <Box
        hidden={!isActiveComponent('Product Receipt Details')}
        sx={{ width: '100%' }}
      >
        <ProductReceiptDetails
          merchantOptions={merchantOptions}
          customerOptions={customerOptions}
        />
      </Box>
      <Box
        hidden={!isActiveComponent('Facility Details')}
        sx={{ width: '100%' }}
      >
        <FacilityDetails />
      </Box>
      <Box hidden={!isActiveComponent('Analytics')} sx={{ width: '100%' }}>
        <Analytics />
      </Box>
      <Box
        hidden={!isActiveComponent('Product Receipt Breakdown')}
        sx={{ width: '100%' }}
      >
        <ProductReceiptBreakdown />
      </Box>
      <Box hidden={!isActiveComponent('Review')} sx={{ width: '100%' }}>
        <Review />
      </Box>
    </>
  );
};
export default RequestProductReceiptPage;
