import { Box, Stack, Typography, Button as MUIButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import RequestProductReceiptBottomNav from '../nav/bottomNav';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { RequestProductReceiptContext } from '../context/requestProductReceiptContext';
import _ from 'lodash';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';

const Review = () => {
  const { data } = useContext(RequestProductReceiptContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const prcs = data.quantity.map((q, index) => ({
    quantity: q,
    amount: data.amount[index] || '',
    expiry_date: '2024-08-31'
  }));

  const totalPages = prcs.length;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'inherit',
          width: '29rem',
          margin: '0 auto',
          marginTop: '1.5rem',
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          mb: 3
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Review Request
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Confirm that the common details are correct.
          </Typography>
        </Stack>
        <Box padding="1rem" mb={'1.25rem'}>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingBottom={isOpen ? '.75rem' : '0rem'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              Primary Details
            </Typography>

            <SvgWrapper
              icon={isOpen ? ChevronUp : ChevronDown}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#667085'
              }}
            />
          </Stack>
          {isOpen ? (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              sx={{
                pb: '0rem'
              }}
            >
              <Stack
                direction="column"
                width="inherit"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    rowGap: '0.625rem',
                    columnGap: '0.9375rem'
                  }}
                >
                  {data.tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        background: '#F5F6F7',
                        borderRadius: '0.375rem',
                        height: '2.25rem',
                        display: 'flex',
                        alignItem: 'center',
                        p: '0.5rem'
                      }}
                    >
                      <Box
                        mr={'0.3125rem'}
                        sx={{
                          display: 'inline'
                        }}
                      >
                        <SvgWrapper
                          icon={TagIcon}
                          width=".75rem"
                          height=".75rem"
                          color="##5B5DE3"
                        />
                      </Box>
                      <Typography variant="bodyMediumMedium" color="#5B5DE3">
                        {`${tag.tagsType} : `}
                      </Typography>
                      <Typography variant="bodyMediumMedium" color="#5B5DE3">
                        {tag.tagsName}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Customer
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {_.startCase(data.customer_name)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Merchant
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {_.startCase(data.merchant)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Product
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {_.startCase(data.productName)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Total Quantity
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {data.total_quantity}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Facility
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {data.facility_type}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Repayment Schedule
                  </Typography>
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    {data.repayment_schedule_days} days
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Box>

      {/* Review Pagination for Batch Details */}

      <Box
        padding="1rem .75rem"
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          width: '29rem',
          pb: '0rem',
          margin: '0 auto',
          mb: '6rem'
        }}
      >
        {totalPages > 0 ? (
          <>
            <Box
              sx={{
                maxHeight: '25rem',
                overflowY: 'auto'
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{
                  borderRadius: '0.5rem',
                  border: '0.0625rem solid #F5F6F7',
                  background: 'rgba(102, 112, 133, 0.02)',
                  pb: '0rem',
                  mb: '1rem'
                }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: '0.063rem solid #F5F6F7',
                    width: '100%',
                    padding: '1rem .75rem'
                  }}
                >
                  <Typography variant="bodyMediumSemibold" color="#475467">
                    Product Receipt {currentIndex + 1}
                  </Typography>
                </Stack>

                <Stack
                  direction="column"
                  width="inherit"
                  justifyContent="space-between"
                  padding="0rem .75rem"
                >
                  <Stack
                    direction="row"
                    width="inherit"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '.063rem solid #FCFCFD',
                      padding: '1rem 0rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Amount
                    </Typography>
                    <Typography variant="bodyLargeSemibold" color="#475467">
                      ₦{formattedNumber(prcs[currentIndex].amount) || '---'}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    width="inherit"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '.063rem solid #FCFCFD',
                      padding: '1rem 0rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Quantity
                    </Typography>
                    <Typography variant="bodyLargeSemibold" color="#475467">
                      {formattedNumber(prcs[currentIndex].quantity) || '---'}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    width="inherit"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '.063rem solid #FCFCFD',
                      padding: '1rem 0rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Expiry Date
                    </Typography>
                    <Typography variant="bodyLargeSemibold" color="#475467">
                      {formattedDate(
                        `${prcs[currentIndex].expiry_date}`,
                        'Do MMM, YYYY'
                      ) || '---'}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Box>

            <Stack
              display="flex"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                padding: '.75rem 0rem',
                borderTop: '.063rem solid #F5F6F7'
              }}
            >
              <Typography
                variant="bodyMediumSemibold"
                color="#98A2B3"
                marginRight="1rem"
              >
                {currentIndex + 1}/{prcs.length}
              </Typography>

              <Box gap="1rem">
                {currentIndex !== 0 && (
                  <MUIButton
                    variant="outlined"
                    sx={{
                      border: 'none',
                      stroke: '#3E7DF8',
                      '&:hover': {
                        border: 'none'
                      },
                      '&:active': {
                        border: 'none'
                      },
                      '&:disabled': {
                        border: 'none',
                        background: 'grey'
                      }
                    }}
                    onClick={() => {
                      setCurrentIndex(currentIndex - 1);
                    }}
                  >
                    <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                      Back
                    </Typography>
                  </MUIButton>
                )}

                <MUIButton
                  variant="outlined"
                  sx={{
                    border: 'none',
                    stroke: '#3E7DF8',
                    '&:hover': {
                      border: 'none'
                    },
                    '&:active': {
                      border: 'none'
                    },
                    '&:disabled': {
                      border: 'none',
                      display: 'none'
                    }
                  }}
                  endIcon={<ArrowLeft />}
                  onClick={() => {
                    setCurrentIndex(currentIndex + 1);
                  }}
                  disabled={prcs.length - 1 === currentIndex}
                >
                  <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                    Next
                  </Typography>
                </MUIButton>
              </Box>
            </Stack>
          </>
        ) : (
          <Typography variant="bodyMediumSemibold" color="#475467">
            No Product Receipt data available.
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        <RequestProductReceiptBottomNav
          nextButtonLabel="Submit"
          isNextDisabled={false}
        />
      </Box>
    </>
  );
};
export default Review;
