import {
  createApi,
  BaseQueryFn,
  BaseQueryApi
} from '@reduxjs/toolkit/query/react';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import type { RootState } from '../store';
import { setUserLogout } from '../slices/authSlice';
import { updateHeaderWithDevToken } from 'utilities/devToken';

const axiosBaseQuery = ({
  baseUrl
}: {
  baseUrl: string;
}): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
> => {
  return async ({ url, method, data, params, headers }, api: BaseQueryApi) => {
    try {
      // Also get organization id/ user id
      const getState = api.getState as () => RootState;
      const { userId, organizationId, supplyChainProfileId, devAuthToken } =
        getState().auth;

      const { user, token } = getState().register;

      // Replace organization id  and user id in url as needed
      const finalUrl = url
        .replace('{organizationId}', organizationId)
        .replace('{userId}', userId || user.userid)
        .replace('{supplyChainProfileId}', supplyChainProfileId);

      // Update headers with csrf token
      const csrfToken = Cookies.get('csrftoken');
      const prepareHeaders = updateHeaderWithDevToken(
        {
          ...headers,
          'X-CSRFToken': csrfToken
        },
        devAuthToken || token
      );

      const result = await axios({
        url: baseUrl + finalUrl,
        method,
        data,
        params,
        headers: prepareHeaders,
        withCredentials: true
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      // If the request returned an authorized error we need to log the user out
      if (err.response?.status == 401) {
        sessionStorage.clear();
        // api.dispatch(api.util.resetApiState()); Not sure we actually need this but will leave for now
        api.dispatch(setUserLogout());
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };
};

export const allTagTypes = [
  'productReceipts',
  'productReceiptMetadata',
  'members',
  'teams',
  'branches',
  'products',
  'partnerships',
  'user',
  'auth',
  'loanApplications',
  'notification',
  'workflow',
  'workflowComments',
  'permissions',
  'groupedPermissions',
  'roleRequest',
  'featureFlag',
  'getCentralVaultProductReceipts',
  'requestProductReceipt'
];

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/`
  }),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: false,
  endpoints: () => ({}),
  tagTypes: allTagTypes
});
