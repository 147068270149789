import React from 'react';
import { Box, Paper, Divider, Typography } from '@mui/material';
import DisplayCard from './displayCard';
import { OrganizationType } from 'services/enums/organization';
import { useAppSelector } from 'services/hook';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import PaymentHistoryTable from './table/paymentHistoryTable';
import PaymentOverviewTable, {
  demoPaymentOverviewData
} from './table/paymentOverviewTable';

const ProductReceiptPayment = () => {
  const navigate = useNavigate();
  const { organizationType } = useAppSelector(state => state.auth);
  const isDistributor = organizationType === OrganizationType.DISTRIBUTOR;
  const isLender = organizationType === OrganizationType.LENDER;

  const handleMakePayment = () => {
    navigate('/payment-summary');
  };

  const demoData = {
    pendingPayment: 5500000,
    facilityAmount: 5500000,
    totalPaid: 3000000,
    paymentDate: '01 Aug. 2024',
    outstandingLoans: 4500000,
    nextPaymentDate: 'Feb. 5, 2025'
  };

  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isDistributor && (
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            height: 122,
            p: 2,
            background: 'linear-gradient(0deg, white 0%, white 100%)',
            borderRadius: 2,
            border: '.0625rem solid #F5F6F7',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: '0.75rem'
          }}
        >
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
            >
              <Typography variant="bodyMediumMedium" color="#475467">
                Pending Payment
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Typography variant="h4Semibold" color="#344054">
                  ₦ {demoData.pendingPayment?.toLocaleString()}
                  <Typography
                    component="span"
                    variant="h6Semibold"
                    color="#667085"
                    sx={{ fontSize: '1.25rem' }}
                  >
                    .00
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <Button
              transparent
              size="md"
              text="Make Payment"
              color="primary"
              styleOverrides={{
                background: '#3872E2',
                color: '#FFF',
                border: 'none',
                '&:hover': {
                  color: '#3872E2'
                }
              }}
              onClick={handleMakePayment}
            />
          </Box>
        </Paper>
      )}

      {(isDistributor || isLender) && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            border: '0.0625rem solid #F5F6F7',
            width: 'inherit',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            height: '8.125rem'
          }}
        >
          {isLender && (
            <DisplayCard
              title="Facility Amount"
              value={demoData.facilityAmount?.toLocaleString()}
              currency
            />
          )}

          {isLender && (
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                width: '0.0625rem',
                height: '4.3rem',
                margin: '0rem .5rem',
                backgroundColor: '#F2F4F7'
              }}
            />
          )}

          <DisplayCard
            title={isDistributor ? 'Total Paid This Month' : 'Total Paid'}
            value={demoData.totalPaid?.toLocaleString()}
            currency
          />

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '4.3rem',
              margin: '0rem .5rem',
              backgroundColor: '#F2F4F7'
            }}
          />

          <DisplayCard
            title={isDistributor ? 'Next Payment Date' : 'Next Payment'}
            value={demoData.nextPaymentDate}
          />
        </Paper>
      )}

      <Box
        sx={{
          marginTop: '2rem',
          width: 'inherit'
        }}
      >
        {isDistributor ? (
          <PaymentHistoryTable
            data={demoPaymentOverviewData}
            isLoading={false}
          />
        ) : (
          <PaymentOverviewTable
            data={demoPaymentOverviewData}
            isLoading={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductReceiptPayment;
