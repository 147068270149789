import React, { useContext } from 'react';
import {
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { Button } from 'components/button';
import { removeItem } from 'utilities/helperFunc/localStore';
import {
  FormValues,
  RequestProductReceiptContext
} from '../context/requestProductReceiptContext';
import {
  CreateRequestProductReceiptQueryArgs,
  useCreateRequestProductReceiptMutation
} from 'services/api/productReceiptApi';
import ShowInfo from 'components/info/showInfo';

interface BottomNavProps {
  nextButtonLabel?: string;
  onNext?: () => void;
  isNextDisabled?: boolean;
  onBack?: () => void;
}

const RequestProductReceiptBottomNav: React.FC<BottomNavProps> = ({
  nextButtonLabel,
  onNext,
  isNextDisabled,
  onBack
}) => {
  const { activeStep, data, steps, handleNext, handlePrev } = useContext(
    RequestProductReceiptContext
  );
  const [createRequestProductReceipt, { isLoading }] =
    useCreateRequestProductReceiptMutation();

  const navigate = useNavigate();

  const defaultIsNextDisabled = activeStep === 0;

  const disabled =
    isNextDisabled !== undefined ? isNextDisabled : defaultIsNextDisabled;

  const handleNextClick = () => {
    if (onNext) {
      onNext();
    } else {
      if (activeStep === steps.length - 1) {
        onSubmit(data);
      } else {
        handleNext();
      }
    }
  };

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      handlePrev();
    }
  };

  const onSubmit = async (entry: FormValues) => {
    // Map each batch's quantity from the quantity array into a product_receipt_data item.
    const productReceiptData = entry.quantity.map(qty => ({
      total_quantity: parseFloat(qty),
      // Use the tags provided in the form, or fall back to a default tag.
      tags:
        entry.tags.length > 0
          ? entry.tags.map(tag => ({ key: tag.tagsType, value: tag.tagsName }))
          : []
    }));
    const payload: CreateRequestProductReceiptQueryArgs = {
      merchant: entry.merchantID,
      customer: entry.customerID,
      product_variant: entry.product,
      product_receipt_data: productReceiptData,
      facility_data: {
        facility_type: entry.facility_type,
        total_amount: entry.total_amount,
        initial_amount: entry.facility_amount,
        subsequent_amount: entry.subsequent_fee,
        repayment_schedule: Number(entry.repayment_schedule_days),
        expiry_date: new Date(entry.tenor).toISOString()
      },
      publish_request: true
    };

    await createRequestProductReceipt(payload)
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Product Receipt Request Sent',
          subText: `Your request has been sent to ${entry.merchant} ON behalf of ${entry.customer_name}`,
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        removeItem('requestProductReceiptFormData');
        removeItem('requestProductReceiptStep');
        navigate('/');
      })
      .catch(() => {
        ShowInfo({
          message: 'Product Receipt Request not created',
          subText: 'Product Receipt Request has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <>
      <Toolbar
        sx={{
          width: 'inherit',
          padding: '0.75rem 3rem',
          height: '100%',
          borderTop: '0.063rem solid #F5F6F7',
          background: '#fff'
        }}
      >
        <Stack
          width="100%"
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MUIButton
            variant="text"
            startIcon={
              <Avatar
                src={chevronLeft}
                sx={{ width: '1.25rem', height: '1.25rem' }}
              />
            }
            onClick={() => {
              removeItem('requestProductReceiptFormData');
              removeItem('requestProductReceiptStep');
              removeItem('createProductReceiptSelectedType');
              removeItem('createProductReceiptSelectedIssueType');
              navigate('/');
            }}
          >
            <Typography variant="bodyMediumSemibold" color="#2C59B0">
              Exit Request Product Receipt
            </Typography>
          </MUIButton>

          <Stack direction="row" gap=".75rem">
            {activeStep !== 0 && (
              <Button
                color="grey"
                transparent
                size="md"
                text="Back"
                styleOverrides={{
                  width: '10rem',
                  padding: '.75rem',
                  borderRadius: '.5rem'
                }}
                onClick={handleBackClick}
              />
            )}

            <Button
              color="primary"
              size="md"
              text={
                nextButtonLabel ||
                (activeStep === steps.length - 1
                  ? 'Create Product Receipt'
                  : activeStep === 2
                    ? 'Review'
                    : 'Next')
              }
              styleOverrides={{
                minWidth: '10rem',
                padding: '.75rem',
                borderRadius: '.5rem'
              }}
              onClick={handleNextClick}
              disabled={disabled}
              loadingIcon={isLoading}
            />
          </Stack>
        </Stack>
      </Toolbar>
    </>
  );
};
export default RequestProductReceiptBottomNav;
