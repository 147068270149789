import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { theme } from 'themes/theme';
import { DateRangeType, Nav } from './chartsNav';
import BarChart from '../../../../components/charts/barchart';
import { useGetTopProductsQuery } from 'services/api/analyticsApi';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

const defaultDateRange = getDateRange('This Month');
const TopProductsChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: topProducts } = useGetTopProductsQuery({
    params: {
      start: dateRange.startDate
        ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
        : '2024-01-01',
      include_closed: 'true',
      ...(dateRange.endDate && {
        end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
      }),
      limit: '5'
    }
  });

  const labels = topProducts ? topProducts.map(item => item.name) : [];
  const chartDataset = [
    {
      data: topProducts ? topProducts.map(item => item.value) : [],
      label: 'Top Sales'
    }
  ];

  return (
    <Box
      sx={{
        width: '48%',
        height: '31.25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1rem'
      }}
    >
      <Nav
        header="Top Products"
        caption="Most product receipts redeemed"
        setDateRange={setDateRange}
        showButton
      />
      <BarChart
        labels={labels}
        datasets={chartDataset.map(dataset => ({
          ...dataset,
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light
        }))}
        horizontal={true}
      />
    </Box>
  );
};

export default TopProductsChart;
