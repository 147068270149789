import {
  Backdrop,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { ReactComponent as Filter } from 'assets/svg/filterLines.svg';
import { ReactComponent as Opened } from 'assets/svg/opened.svg';
import { SvgWrapper } from 'components/svg';
import React from 'react';
import GeneralNotifications from './generalNotifications';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const NotificationsDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const getStartingPoint = () => {
    const width = window.innerWidth;
    return (width / 12) * 2.3;
  };

  const getDrawerWidth = () => {
    const width = window.innerWidth;
    const drawerWidth = 24.75 * 16;
    const calcDynamicDrawerWidth = (drawerWidth / width) * 100;
    return `${calcDynamicDrawerWidth}`;
  };

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <Backdrop
        open={open}
        onClick={onClose}
        sx={{
          zIndex: theme.zIndex.drawer,
          transition: 'opacity 0.6s ease-in-out',
          backgroundColor: 'rgb(2,0,36)',
          background:
            'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 10%, rgba(0,0,0,0.2) 100%)'
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          height: '100%',
          top: '3.90rem',
          width: `${getDrawerWidth()}%`,
          left: getStartingPoint(),
          display: 'flex',
          background: 'white',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden',
          clipPath: open ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
          transform: open ? 'scaleX(1)' : 'scaleX(0)',
          transformOrigin: 'left',
          transition: 'all 0.6s ease-in-out',
          zIndex: theme.zIndex.drawer + 1
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          padding="1rem 1.5rem"
          spacing={1}
          justifyContent="space-between"
          sx={{
            animation: open
              ? 'slideUp 0.5s ease-in-out, fadeIn 0.5s ease-in-out'
              : 'none',
            borderBottom: '.063rem solid #F2F4F7',
            border: '0.09375rem solid transparent'
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#475467">
            Activity Feed
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="1.25rem"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap=".5rem"
            >
              <SvgWrapper
                icon={Opened}
                width="1rem"
                height="1rem"
                styleOverrides={{ fill: '#98A2B3' }}
              />
              <Typography variant="bodyMediumMedium" color="#475467">
                Mark all as read
              </Typography>
            </Stack>

            <Box
              width="1.75rem"
              height="1.75rem"
              style={{
                display: 'flex',
                padding: '.5rem',
                borderRadius: '.5rem',
                background: '#F5F6F7'
              }}
            >
              <SvgWrapper
                icon={Filter}
                width="0.75rem"
                height="0.75rem"
                styleOverrides={{
                  fill: 'none',
                  stroke: '#667085'
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <Divider />

        <Stack
          width="100%"
          sx={{
            height: 'inherit',
            animation: open ? 'fadeIn 0.5s ease-in-out' : 'none',
            mt: 1
          }}
        >
          <Box
            sx={{
              maxHeight: '100vh',
              overflow: 'auto'
            }}
          >
            <GeneralNotifications />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default NotificationsDrawer;
