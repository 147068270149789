import React from 'react';
import { Box, Typography } from '@mui/material';
import ReceivedPaymentTable, {
  demoReceivedPaymentsData
} from './receivedPaymentTable';
import { WalletBalanceCard } from './walletBalanceCard';

const MerchantWalletPage = () => {
  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}
        >
          <Typography variant="h7Bold" color="#475467">
            Wallet/Payments
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            Overview of all transactions, balances, and payment activities
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%', mt: '3rem' }}>
        <WalletBalanceCard />
      </Box>
      <Box role="tabpanel" sx={{ width: '100%', mt: '3rem' }}>
        <ReceivedPaymentTable
          data={demoReceivedPaymentsData}
          isLoading={false}
        />
      </Box>
    </Box>
  );
};

export default MerchantWalletPage;
