import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import HeatMap from 'components/charts/heatMap';
import { useGetAgeAnalysisVisualizationsQuery } from 'services/api/analyticsApi';

const App: React.FC = () => {
  const { data } = useGetAgeAnalysisVisualizationsQuery({
    entity: 'distributor'
  });

  return (
    <>
      {data ? (
        <Box
          position="relative"
          sx={{
            marginTop: '2rem',
            width: 'inherit',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            border: '.063rem solid #F5F6F7',
            padding: '1.8rem',
            pb: '0.75rem',
            boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
          }}
        >
          <Stack direction="column" gap={0.5} position="absolute">
            <Typography variant="h7Semibold" color="#475467">
              Customer Receipt Age Analysis
            </Typography>

            <Typography variant="bodyMediumMedium" color="#98A2B3">
              View the age breakdown of all product receipts within a specific
              branch
            </Typography>
          </Stack>
          <HeatMap data={data.slice(0, 10)} />
        </Box>
      ) : null}
    </>
  );
};

export default App;
