import React from 'react';
import { useAppSelector } from 'services/hook';
import { OrganizationType } from 'services/enums/organization';
import CreateProductReceiptLayout from './createProductReceipt/layout/createProductReceiptLayout';
import RequestProductReceiptLayout from './requestProductReceipt/layout/requestProductReceiptLayout';

const ProductReceiptLayout: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const { organizationType } = useAppSelector(state => state.auth);

  if (organizationType === OrganizationType.MERCHANT) {
    return <CreateProductReceiptLayout>{children}</CreateProductReceiptLayout>;
  } else if (organizationType === OrganizationType.LENDER) {
    return (
      <RequestProductReceiptLayout>{children}</RequestProductReceiptLayout>
    );
  }

  return <>{children}</>;
};

export default ProductReceiptLayout;
