import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { useGetProductsQuery } from 'services/api/productApi';
import { useAppSelector } from 'services/hook';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import { ProductReceiptType } from 'services/types/productReceipt';
import { removeItem } from 'utilities/helperFunc/localStore';
import { CreateProductReceiptContext } from './context/createProductReceiptContext';
import SelectType from './steps/selectType';
import SelectIssueMethod from './steps/selectIssueMethod';
import BatchProductReceiptDetails from './steps/batch/batchProductReceiptDetails';
import SingleProductReceiptDetails from './steps/single/singleProductReceiptDetails';
import ReviewBatchProductReceipts from './steps/batch/reviewBatchProductReceipts';
import ReviewSingleProductReceipts from './steps/single/reviewSingleProductReceipts';

const CreateProductReceiptPage = () => {
  const { activeStep, request, selectedType, steps } = useContext(
    CreateProductReceiptContext
  );

  const { supplyChainProfileId } = useAppSelector(state => state.auth);
  const { data: productData } = useGetProductsQuery(supplyChainProfileId);
  const { data: bankData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,lender' }
  });
  const { data: customerData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,end_user' }
  });

  const bankOptions = useMemo(() => {
    return (
      (bankData &&
        Object.values(bankData).map(bank => ({
          title: bank.name,
          value: bank.organization_id
        }))) ||
      []
    );
  }, [bankData]);

  const customerOptions = useMemo(() => {
    return (
      (customerData &&
        Object.values(customerData).map(customer => ({
          title: customer.name,
          value: customer.organization_id
        }))) ||
      []
    );
  }, [customerData]);

  const isActiveComponent = (el: string) => {
    return el === steps[activeStep]?.label;
  };

  window.onpopstate = () => {
    removeItem('createProductReceiptFormData');
    removeItem('createProductReceiptStep');
    removeItem('createProductReceiptSelectedType');
    removeItem('createProductReceiptSelectedIssueType');
  };

  return (
    <>
      <Box hidden={!isActiveComponent('Select Type')} sx={{ width: '100%' }}>
        <SelectType />
      </Box>

      <Box hidden={!isActiveComponent('Issue Method')} sx={{ width: '100%' }}>
        <SelectIssueMethod />
      </Box>

      <Box
        hidden={!isActiveComponent('Product Receipt Details')}
        sx={{ width: '100%' }}
      >
        {selectedType === ProductReceiptType.SINGLE ? (
          <SingleProductReceiptDetails
            request={request}
            bankOptions={bankOptions}
            customerOptions={customerOptions}
            productData={productData}
          />
        ) : (
          <BatchProductReceiptDetails
            request={request}
            bankOptions={bankOptions}
            customerOptions={customerOptions}
            productData={productData}
          />
        )}
      </Box>

      <Box
        hidden={!isActiveComponent('Review Product Receipt')}
        sx={{ width: '100%' }}
      >
        {selectedType === ProductReceiptType.SINGLE ? (
          <ReviewSingleProductReceipts productData={productData} />
        ) : (
          <ReviewBatchProductReceipts productData={productData} />
        )}
      </Box>
    </>
  );
};

export default CreateProductReceiptPage;
