import * as Yup from 'yup';

export const productReceiptDetailsValidator = Yup.object({
  customer_name: Yup.string().required('This field is required'),
  merchant: Yup.string().required('This field is required'),
  product: Yup.string().required('This field is required'),
  total_quantity: Yup.string()
    .required('This field is required')
    .matches(/^[0-9,]+$/, 'Total quantity should be a valid number'),

  tags: Yup.array().of(
    Yup.object({
      tagsType: Yup.string(),
      tagsName: Yup.string()
    })
  ),
  //

  // The fields not relevant to this step are marked as not required.
  facility_type: Yup.string().notRequired(),
  repayment_schedule: Yup.string().notRequired(),
  guarantee_fee: Yup.string().notRequired(),
  subsequent_fee: Yup.string().notRequired(),
  tenor: Yup.string().notRequired()
});
