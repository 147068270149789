import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ProductApiData } from 'services/types/product';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import {
  CreateProductReceiptContext,
  ProductReceiptIssueType
} from '../../context/createProductReceiptContext';
import _ from 'lodash';
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg';
import CreateProductReceiptBottomNav from '../../nav/bottomNav';
import { SvgWrapper } from 'components/svg';

type ReviewSinglePRCProps = {
  productData: ProductApiData[] | undefined;
};

const ReviewSingleProductReceipts = ({ productData }: ReviewSinglePRCProps) => {
  const { data, selectedIssueType } = useContext(CreateProductReceiptContext);

  // Convert quantity and expiry_date into string if they are arrays (assume the first element)
  const quantity: string = Array.isArray(data.quantity)
    ? data.quantity[0]
    : data.quantity;
  const expiryDate: string = Array.isArray(data.expiry_date)
    ? data.expiry_date[0]
    : data.expiry_date;

  // Helper: Given a product ID, return the product name.
  const productName = (productId: string): string => {
    const product = productData?.find(item => item.product_id === productId);
    return product ? product.name : '---';
  };

  // Helper: Given a product and a variant ID, return the variant name (or currency).
  const productVariantName = (productId: string, variantId: string): string => {
    const product = productData?.find(item => item.product_id === productId);
    if (product && product.variants) {
      const variant = product.variants.find(
        v => v.product_variant_id === variantId
      );
      return variant ? variant.currency : '---';
    }
    return '---';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'inherit',
          width: '29rem',
          margin: '0 auto',
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          marginTop: '1.5rem',
          mb: '1.5rem'
        }}
      >
        <Stack
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Review Details
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Ensure product receipt details are accurate
          </Typography>
        </Stack>

        <Box padding=".5rem 0rem">
          {data.tags && data.tags.length > 0 && (
            <Stack gap={1} padding=".5rem .75rem">
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    rowGap: '0.625rem',
                    columnGap: '0.9375rem'
                  }}
                >
                  {data.tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        background: '#F5F6F7',
                        borderRadius: '0.375rem',
                        height: '2.25rem',
                        display: 'flex',
                        alignItem: 'center',
                        p: '0.5rem'
                      }}
                    >
                      <Box
                        mr={'0.3125rem'}
                        sx={{
                          display: 'inline'
                        }}
                      >
                        <SvgWrapper
                          icon={TagIcon}
                          width=".75rem"
                          height=".75rem"
                          color="##5B5DE3"
                        />
                      </Box>
                      <Typography variant="bodyMediumMedium" color="#5B5DE3">
                        {`${tag.tagsType} : `}
                      </Typography>
                      <Typography variant="bodyMediumMedium" color="#5B5DE3">
                        {tag.tagsName}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Customer
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {_.startCase(data.customer_name)}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Issuance Method
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {selectedIssueType || '---'}
                </Typography>
              </Stack>

              {selectedIssueType === ProductReceiptIssueType.INTERMEDIARY && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Bank
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {_.startCase(data.bank)}
                  </Typography>
                </Stack>
              )}

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Product
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {_.startCase(productName(data.product))}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Product Option
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {_.startCase(
                    `${productName(data.product)} ${productVariantName(data.product, data.product_variant)}`
                  )}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Unit
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {data.unit || '---'}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Price
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {formattedNumber(data.amount, true) || '₦0'}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Quantity
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {formattedNumber(quantity) || '---'}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ padding: '1rem 0rem' }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Expiry Date
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {formattedDate(expiryDate, 'Do MMMM, YYYY') || '---'}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <CreateProductReceiptBottomNav
          nextButtonLabel="Submit"
          isNextDisabled={false}
        />
      </Box>
    </>
  );
};

export default ReviewSingleProductReceipts;
