import React from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import AvatarGroup from 'components/common/avatarGroup';
import LinearProgressBar from 'components/progress/linear';
import { ReactComponent as WorkflowIcon } from 'assets/svg/workflow.svg';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { useNavigate } from 'react-router-dom';

interface WorkflowCardProps {
  totalSteps: number;
  completedSteps: number;
  targetObject: string;
  targetObjectId: string;
  lastActionedOn: string | null;
  title: string;
}
const WorkflowCard = ({
  totalSteps,
  completedSteps,
  targetObject,
  targetObjectId,
  lastActionedOn,
  title
}: WorkflowCardProps) => {
  const navigate = useNavigate();
  const percentageCompletion = Math.round((completedSteps / totalSteps) * 100);

  const handleClick = () => {
    switch (targetObject) {
      case 'role_request':
        navigate(`/settings/permissions/requests/${targetObjectId}/workflow`);
        break;

      case 'product_receipt':
        navigate(`/product-receipts/${targetObjectId}/workflow`);
        break;
    }
  };

  return (
    <Box
      width="inherit"
      sx={{
        padding: '0.9rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#FFF',
        gap: '1rem'
      }}
      onClick={handleClick}
    >
      <Stack
        sx={{
          width: '40%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '0.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.5rem',
            backgroundColor: '#F5F6F7',
            borderRadius: '0.5rem'
          }}
        >
          <SvgWrapper
            icon={WorkflowIcon}
            width="1rem"
            height="1rem"
            color="#98A2B3"
          />
        </Box>
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="bodyMediumMedium">{title}</Typography>
          {lastActionedOn && (
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Updated on {formattedDate(lastActionedOn, 'MMM Do, hh:ma')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '25%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <LinearProgressBar
            value={percentageCompletion}
            color="#3E7DF8"
            borderRadius={5}
            height={4}
          />
          <Typography variant="bodyMediumMedium" color="#475467">
            {percentageCompletion}%
          </Typography>
        </Stack>
        <Stack width="100%" direction="column" alignItems="flex-start">
          <Typography variant="bodyMediumMedium" color="#475467">
            {completedSteps}/{totalSteps} steps
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '20%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Chip size="xs" label="Approval pending" color="error" />
      </Stack>
      <Stack
        sx={{
          width: '15%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <AvatarGroup
          type="text"
          srcs={['SF', 'ND', 'LD']}
          maxShown={5}
          size="md"
        />
      </Stack>

      <Stack
        width="2rem"
        height="2rem"
        style={{
          display: 'flex',
          padding: '.5rem',
          borderRadius: '6.25rem',
          background: '#FCFCFD'
        }}
      >
        <SvgWrapper
          icon={ChevronRightIcon}
          height="1rem"
          width="1rem"
          styleOverrides={{ fill: 'none', stroke: '#000' }}
        />
      </Stack>
    </Box>
  );
};

export default WorkflowCard;
