import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Card } from 'assets/svg/cardFilled.svg';
import { ReactComponent as Menu } from 'assets/svg/menu.svg';
import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Table } from 'components/table';
import { Chip } from 'components/chip';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/terminalEmptyStateIcon.svg';
import { SvgWrapper } from 'components/svg';

interface DistributorsPendingPaymentTableData {
  prcCode: string;
  merchant: string;
  product: string;
  quantity: string;
  totalValue: string;
  bank: string;
  status: string;
}

interface DistributorsPendingPaymentProps {
  data: DistributorsPendingPaymentTableData[];
  isLoading: boolean;
  title?: string;
  subtitle?: string;
  headerButton?: React.ReactNode;
  emptyDataTitle?: string;
  emptyDataSubtitle?: string;
}

export const demoPendingPaymentsData: DistributorsPendingPaymentTableData[] = [
  {
    prcCode: 'DANG-1267',
    merchant: 'Dangote PLC 1',
    product: 'Cement bags 1',
    quantity: '50,000 bags',
    totalValue: '200,000,000',
    bank: 'Globus Bank',
    status: 'Pending'
  },
  {
    prcCode: 'DANG-1268',
    merchant: 'Dangote PLC 2',
    product: 'Cement bags 2',
    quantity: '40,000 bags',
    totalValue: '160,000,000',
    bank: 'Globus Bank',
    status: 'Overdue'
  },
  {
    prcCode: 'DANG-1269',
    merchant: 'Dangote PLC 3',
    product: 'Cement bags 3',
    quantity: '30,000 bags',
    totalValue: '120,000,000',
    bank: 'Globus Bank',
    status: 'Pending'
  }
];

const chipData: { text: string; color: 'error' | 'success' | 'warning' }[] = [
  {
    text: 'Pending',
    color: 'warning'
  },
  {
    text: 'Overdue',
    color: 'error'
  }
];

const columnHelper = createColumnHelper<DistributorsPendingPaymentTableData>();
export const pendingColumns = [
  columnHelper.accessor('prcCode', {
    header: 'PRC Code',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('merchant', {
    header: 'Merchant',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('product', {
    header: 'Product',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('totalValue', {
    header: 'Total Value',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('bank', {
    header: 'Bank',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: info =>
      chipData.map(chip => {
        const value = info.getValue();
        return (
          <Box key={chip.text}>
            {value === chip.text && (
              <Chip label={value} size="sm" color={chip.color} />
            )}
          </Box>
        );
      }),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<DistributorsPendingPaymentTableData>[];

const DistributorsPendingPaymentTable = ({
  data,
  isLoading,
  title,
  subtitle,
  headerButton,
  emptyDataTitle,
  emptyDataSubtitle
}: DistributorsPendingPaymentProps) => {
  const navigate = useNavigate();
  const pendingPayments = data;

  const handleMakePayment = () => {
    navigate('payment-summary');
  };

  const updatedPendingPaymentsList = useMemo(() => {
    return pendingPayments.map(
      (pendingItem: DistributorsPendingPaymentTableData) => ({
        prcCode: pendingItem.prcCode || 'N/A',
        merchant: pendingItem.merchant || 'N/A',
        product: pendingItem.product || 'N/A',
        quantity: pendingItem.quantity || '0',
        totalValue: pendingItem.totalValue || '$0',
        bank: pendingItem.bank || 'N/A',
        status: pendingItem.status || 'Unknown'
      })
    );
  }, [pendingPayments]);

  const menuItems = [
    {
      image: Card,
      displayText: 'Make Payment',
      onClickMenuItem: handleMakePayment,
      fill: '#98A2B3'
    },
    {
      image: Menu,
      displayText: 'View Details',
      onClickMenuItem: (rowData: DistributorsPendingPaymentTableData) => {
        navigate(`/product-receipts/${rowData.prcCode}/details`);
      },
      fill: '#98A2B3'
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Table<DistributorsPendingPaymentTableData>
        hover
        columns={pendingColumns}
        data={updatedPendingPaymentsList}
        onClickRow={(rowData: DistributorsPendingPaymentTableData) =>
          console.log(`Hi from row ${rowData.merchant}`)
        }
        optionsButton
        showMenu
        menuItemProps={menuItems}
        showSearch={false}
        enableFilter={false}
        searchPlaceholderText="Search using product receipt code"
        loadingTable={isLoading}
        title={title}
        subtitle={subtitle}
        headerButton={headerButton}
        emptyDataTitle={emptyDataTitle ?? 'No Pending Payments'}
        emptyDataSubtitle={
          emptyDataSubtitle ?? 'You don’t have any pending payment yet'
        }
        emptyDataIcon={
          <SvgWrapper icon={EmptyStateIcon} width="9rem" height="9rem" />
        }
      />
    </Box>
  );
};

export default DistributorsPendingPaymentTable;
