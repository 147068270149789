import { convertToQueryString } from 'services/urlSearchParams';
import { api } from './baseApi';
import {
  AggregateProductReceiptAnalyticsApiData,
  MonthlyProductReceiptAnalyticsApiData,
  TopBranchesAnalyticsApiData,
  ReportsApiData,
  TopOrganizationsAnalyticsApiData,
  TopProductsAnalyticsApiData,
  TopProductReceiptHoldersAnalyticsApiData,
  AgeAnalysisVisualizationsApiData,
  EndorsementWorkflowAnalyticsApiData
} from 'services/types/analytics';

export type AggregatePRCsQueryArgs = {
  params: {
    start: string;
    end?: string;
    include_closed?: 'true' | 'false';
    granularity: 'daily' | 'monthly' | 'yearly' | 'weekly' | 'quarterly';
    branches?: string;
    date_logic?: 'created' | 'endorsed' | 'closed';
  };
};

export type AgeAnalysisQueryArgs = {
  entity: string;
};

export type MonthlyPRCsQueryArgs = {
  params: {
    start: string;
    end: string;
    branches?: string;
  };
};

type GenericTopObjectQueryArgs = {
  params: {
    start: string;
    end?: string;
    dealers?: string;
    include_closed?: 'true' | 'false';
    limit?: string;
  };
};

type GenericPRCHolderQueryArgs = {
  params: {
    start: string;
    end?: string;
    holder_type: 'merchant' | 'lender' | 'distributor';
    limit?: string;
  };
};

type ReportsApiQueryArgs = {
  params: {
    start?: string;
    end?: string;
  };
};

export const analyticsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAggregateProductReceipts: builder.query<
      AggregateProductReceiptAnalyticsApiData[],
      AggregatePRCsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/aggregate/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getAgeAnalysisVisualizations: builder.query<
      AgeAnalysisVisualizationsApiData[],
      AgeAnalysisQueryArgs
    >({
      query: ({ entity }) => {
        return {
          url: `{supplyChainProfileId}/analytics/visualizations/age-analytics/?entity=${entity}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getEndorsementWorkflowAnalytics: builder.query<
      EndorsementWorkflowAnalyticsApiData[],
      AgeAnalysisQueryArgs
    >({
      query: ({ entity }) => {
        return {
          url: `{supplyChainProfileId}/analytics/visualizations/endorsement-workflow-analytics/?entity=${entity}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getProductReceiptsSummary: builder.query<
      MonthlyProductReceiptAnalyticsApiData,
      MonthlyPRCsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/summary/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getClosedProductReceiptsHolders: builder.query<
      TopProductReceiptHoldersAnalyticsApiData[],
      GenericPRCHolderQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/holders/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopProducts: builder.query<
      TopProductsAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-products/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopMerchants: builder.query<
      TopOrganizationsAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-merchants/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopBranches: builder.query<
      TopBranchesAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-branches/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getAllReports: builder.query<ReportsApiData[], ReportsApiQueryArgs>({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/analytics/reports/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: ['getAllReports']
    }),
    createReport: builder.mutation({
      query: ({ data, params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/analytics/reports/?${queryParams}`,
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
      },
      invalidatesTags: ['getAllReports']
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetAggregateProductReceiptsQuery,
  useGetAggregateProductReceiptsQuery,
  useGetProductReceiptsSummaryQuery,
  useGetClosedProductReceiptsHoldersQuery,
  useGetTopBranchesQuery,
  useGetTopMerchantsQuery,
  useGetTopProductsQuery,
  useLazyGetTopBranchesQuery,
  useLazyGetTopProductsQuery,
  useGetAllReportsQuery,
  useCreateReportMutation,
  useGetAgeAnalysisVisualizationsQuery,
  useGetEndorsementWorkflowAnalyticsQuery,
  usePrefetch
} = analyticsApi;
