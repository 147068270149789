import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { ReactComponent as VisibilityIcon } from 'assets/svg/eye.svg';
import { ReactComponent as ArrowUpRightIcon } from 'assets/svg/arrowUpRight.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';

export const WalletBalanceCard = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        height: 122,
        p: 2,
        background: 'linear-gradient(0deg, white 0%, white 100%)',
        borderRadius: 2,
        border: '.0625rem solid #F5F6F7',
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="bodyMediumMedium" color="#475467">
            Wallet Balance
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <Typography variant="h3Semibold" color="#344054">
              ₦5,500,000
              <Typography component="span" variant="h6Semibold" color="#667085">
                .00
              </Typography>
            </Typography>
            <SvgWrapper
              icon={VisibilityIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{ fill: 'none', stroke: '#475467' }}
            />
          </Box>
        </Box>

        <Button
          icon={ArrowUpRightIcon}
          iconSize="1.5rem"
          iconPosition="end"
          transparent
          size="md"
          text="Transfer"
          color="primary"
          styleOverrides={{
            background: '#ECF2FE',
            border: 'none',
            '&:hover': {
              background: '#3872E2',
              color: '#FFF',
              '& svg': {
                stroke: '#FFFFFF'
              }
            }
          }}
        />
      </Box>
    </Paper>
  );
};
