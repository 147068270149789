import React, { useMemo, useState } from 'react';
import BarChart from '../../../../components/charts/barchart';
import { Box } from '@mui/material';
import { DateRangeType, Nav } from './chartsNav';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import { useGetEndorsementWorkflowAnalyticsQuery } from 'services/api/analyticsApi';

const defaultDateRange = getDateRange('This Month');

const TotalBranchApprovalchart = () => {
  const [, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data } = useGetEndorsementWorkflowAnalyticsQuery({
    entity: 'branches'
  });

  const labels = useMemo(() => data?.map(item => item.entity) || [], [data]);

  const chartDataset = [
    {
      data: data?.map(item => item.count) || [],
      label: ''
    }
  ];

  return (
    <>
      {data && data.length > 1 ? (
        <Box
          sx={{
            marginTop: '2rem',
            gap: '1.75rem',
            width: 'inherit',
            height: '25rem',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            border: '.063rem solid #F5F6F7',
            padding: '1.8rem',
            boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
          }}
        >
          <Nav
            header="Total Approvals Pending per branch"
            caption="See all workflows that are still in progress"
            setDateRange={setDateRange}
          />
          <BarChart
            labels={labels}
            datasets={chartDataset}
            disableCurrencyFormat={true}
          />
        </Box>
      ) : null}
    </>
  );
};

export default TotalBranchApprovalchart;
