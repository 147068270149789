import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { getItem, setItem } from 'utilities/helperFunc/localStore';

export type RequestDetails = {
  id: number;
};

export interface Tag {
  tagsType: string;
  tagsName: string;
}

export interface FormValues {
  customer_name: string;
  customerID: string;
  merchant: string;
  merchantID: string;
  product: string; // product id
  productName: string;
  productPrice: string;
  total_quantity: string;
  facility_type: string;
  tags: Tag[];
  quantity: string[];
  facility_amount: string;
  expiry_date: string[];
  total_amount: string;
  tagsType: string;
  tagsName: string;
  amount: string[];
  repayment_schedule_value: string;
  repayment_schedule_unit: string;
  repayment_schedule_days: string;
  guarantee_fee: string;
  subsequent_fee: string;
  tenor: string;
  unit: string;
  isQuantityManual: boolean; // new flag to indicate a manual edit
}

const initialValues: FormValues = {
  customer_name: '',
  customerID: '',
  merchant: '',
  merchantID: '',
  product: '',
  productName: '',
  total_quantity: '',
  productPrice: '',
  total_amount: '',
  facility_type: '',
  facility_amount: '',
  repayment_schedule_value: '',
  repayment_schedule_unit: 'days',
  repayment_schedule_days: '',
  tags: [],
  quantity: [],
  expiry_date: [],
  tagsType: '',
  tagsName: '',
  amount: [],
  guarantee_fee: '',
  subsequent_fee: '',
  tenor: '',
  unit: '',
  isQuantityManual: false
};

type RequestProductReceiptContextTypes = {
  data: FormValues;
  updateFormData: (data: Partial<FormValues>) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  request: RequestDetails | null;
  setRequest: React.Dispatch<React.SetStateAction<RequestDetails | null>>;
  formState: boolean;
  setFormState: React.Dispatch<React.SetStateAction<boolean>>;
  steps: { label: string }[];
  handleNext: () => void;
  handlePrev: () => void;
};

const RequestProductReceiptContextDefaults: RequestProductReceiptContextTypes =
  {
    data: initialValues,
    updateFormData: () => undefined,
    activeStep: 0,
    setActiveStep: () => undefined,
    request: null,
    setRequest: () => undefined,
    formState: false,
    setFormState: () => undefined,
    steps: [],
    handleNext: () => undefined,
    handlePrev: () => undefined
  };

export const RequestProductReceiptContext =
  createContext<RequestProductReceiptContextTypes>(
    RequestProductReceiptContextDefaults
  );
function RequestProductReceiptContextWrapper({ children }: PropsWithChildren) {
  const [formState, setFormState] = useState<boolean>(false);
  const [request, setRequest] = useState<RequestDetails | null>(null);
  const [data, setData] = useState<FormValues>(
    getItem<FormValues>('requestProductReceiptFormData', initialValues)
  );
  const [activeStep, setActiveStep] = useState<number>(() =>
    getItem<number>('requestProductReceiptStep', 0)
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const requestIdParam = searchParams.get('requestId');
  const requestId = requestIdParam === 'null' ? null : requestIdParam;

  const steps = [
    {
      label: 'Product Receipt Details'
    },
    {
      label: 'Facility Details'
    },
    {
      label: 'Analytics'
    },
    {
      label: 'Product Receipt Breakdown'
    },
    {
      label: 'Review'
    }
  ];

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < steps.length) {
      setActiveStep(nextStep);
    }
  };

  const handlePrev = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  // updateFormData now accepts Partial<FormValues> and help merges updates easily
  const updateFormData = (newData: Partial<FormValues>) => {
    setData(prev => {
      const updatedData = { ...prev, ...newData };

      // If quantity is updated, recalc total_quantity as the sum of batches.
      if (updatedData.quantity && updatedData.quantity.length > 0) {
        const sumQty = updatedData.quantity.reduce(
          (acc, curr) => acc + parseFloat(curr || '0'),
          0
        );
        updatedData.total_quantity = sumQty.toString();
      }

      // Calculate amount and total_amount based on productPrice and quantity.
      if (updatedData.productPrice && updatedData.quantity.length > 0) {
        updatedData.amount = updatedData.quantity.map(qty =>
          (parseFloat(qty) * parseFloat(updatedData.productPrice)).toString()
        );
        updatedData.total_amount = updatedData.amount
          .reduce((acc, curr) => acc + parseFloat(curr || '0'), 0)
          .toString();
      }

      return updatedData;
    });
  };

  useEffect(() => {
    setItem('requestProductReceiptStep', activeStep);
    setItem('requestProductReceiptFormData', data);
  }, [activeStep, data, setData]);

  useEffect(() => {
    if (requestId !== null) {
      setRequest({ id: 1 });
    } else {
      setRequest(null);
    }
  }, [requestId]);

  useEffect(() => {
    if (request) {
      setSearchParams({ requestId: `${request.id}` });
    }
  }, [request]);

  const values = {
    updateFormData,
    handleNext,
    handlePrev,
    steps,
    activeStep,
    setActiveStep,
    request,
    setRequest,
    data,
    formState,
    setFormState
  };

  return (
    <RequestProductReceiptContext.Provider value={values}>
      {children}
    </RequestProductReceiptContext.Provider>
  );
}
export default RequestProductReceiptContextWrapper;
