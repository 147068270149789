import React from 'react';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import logo from 'assets/custom-svg/flux-logo.svg';
import { ReactComponent as Help } from 'assets/svg/helpSolid.svg';
import { Button } from 'components/button';

const PaymentTopNav = () => {
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        borderBottom: '0.063rem solid #F5F6F7'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap=".5rem" alignItems="center">
          <Box
            component="img"
            src={logo}
            sx={{ width: '1.75rem', height: '1.75rem' }}
          />
          <Typography variant="h6Bold" color="#475467">
            Flux
          </Typography>
        </Stack>

        <Button
          icon={Help}
          iconPosition="start"
          transparent
          size="md"
          text="Help"
          color="primary"
          styleOverrides={{
            color: '#667085',
            border: '.094rem solid #F2F4F7',
            borderRadius: '.5rem',
            padding: '0.5rem .75rem',
            cursor: 'pointer',
            '& svg': {
              stroke: '#667085',
              fill: '#fff',
              width: '1rem',
              height: '1rem'
            }
          }}
          onClick={() => window.open('https://taficasa.notion.site/', '_blank')}
        />
      </Stack>
    </Toolbar>
  );
};

export default PaymentTopNav;
