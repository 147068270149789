import React from 'react';
import { OrganizationType } from 'services/enums/organization';
import CreateProductReceiptPage from './createProductReceipt/createProductReceiptPage';
import { useAppSelector } from 'services/hook';
import RequestProductReceiptPage from './requestProductReceipt/requestProductReceiptPage';

const CreatePrcIndex = () => {
  const { organizationType } = useAppSelector(state => state.auth);

  return (
    <>
      {organizationType === OrganizationType.MERCHANT && (
        <CreateProductReceiptPage />
      )}
      {organizationType === OrganizationType.LENDER && (
        <RequestProductReceiptPage />
      )}
    </>
  );
};

export default CreatePrcIndex;
