import React, { useState, useRef } from 'react';
import {
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Box,
  Radio
} from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { theme } from 'themes/theme';
import { shadowsTheme } from 'themes/shadows';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrowDownIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import { RadioChecked, RadioUnChecked } from './formSelect';
import { SvgWrapper } from 'components/svg';
import { Tooltip } from 'components/Tooltip';

interface Option {
  value: string | number;
  label: string;
  subtitle?: string;
}

interface MultiPurposeInputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  type?: 'text' | 'select';
  options?: Option[];
  placeholder?: string;
  required?: boolean;
  onSelect?: (value: string) => void; // Callback for select
  onChange?: (value: string) => void; // Callback for text input
  size?: 'small' | 'medium';
  onOpenMenu?: () => void; // Callback when the dropdown arrow is clicked

  showTooltip?: boolean;
  tooltipHeader?: string;
  tooltipSubText?: string;
}

const MultiPurposeInput = <T extends FieldValues>({
  name,
  label,
  control,
  type = 'text',
  options = [],
  placeholder = '',
  required = false,
  onSelect,
  onChange,
  size = 'medium',
  onOpenMenu,
  showTooltip = false,
  tooltipHeader = '',
  tooltipSubText = ''
}: MultiPurposeInputProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // This ref will track the *entire* text field container
  const textFieldRef = useRef<HTMLDivElement>(null);

  // Use the input element as the anchor so that we can get its width.
  const handleOpenMenu = () => {
    if (textFieldRef.current) {
      setAnchorEl(textFieldRef.current);
    }
    onOpenMenu?.();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: size === 'small' ? 0 : 3,
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '.81rem',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          mb: 1,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography variant="inputLabel" color="#667085" mr={1}>
          {label}
        </Typography>
        {showTooltip && (
          <Tooltip
            color="#485468"
            placement="right-start"
            bgColor="#FFF"
            showArrow
            header={tooltipHeader}
            subText={tooltipSubText}
          >
            <SvgWrapper
              icon={InfoIcon}
              width="1rem"
              height="1rem"
              styleOverrides={{
                fill: '#98A2B3',
                mr: '.5rem',
                mt: '.3rem'
              }}
            />
          </Tooltip>
        )}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        rules={{ required: required ? `${label} is required` : false }}
        render={({ field, fieldState: { error } }) => (
          <>
            <Box
              ref={textFieldRef}
              sx={{
                position: 'relative',
                width: '100%'
              }}
            >
              <TextField
                {...field}
                fullWidth
                // Attach the ref to the input element

                placeholder={placeholder}
                error={!!error}
                helperText={error ? error.message : ''}
                InputProps={{
                  endAdornment: type === 'select' && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOpenMenu}>
                        <ArrowDownIcon stroke="#475467" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={e => {
                  // Only handle text changes if not a select input.

                  field.onChange(e.target.value);
                  onChange?.(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: size === 'small' ? '2.75rem' : '3.2rem',
                    borderRadius: '0.5rem',
                    border: '.094rem solid #EAECF0',
                    background: '#FEFFFF',
                    boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
                    '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.common.btnColor,
                      boxShadow: theme.palette.common.boxShadow
                    }
                  }
                }}
              />
            </Box>

            {type === 'select' && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                // Set the dropdown width to match the input's width
                PaperProps={{
                  style: { width: anchorEl ? anchorEl.clientWidth : undefined },
                  sx: {
                    borderRadius: '0rem 0rem 0.75rem 0.75rem',
                    backgroundColor: '#FFF',
                    boxShadow: shadowsTheme.xxSmallShadowSoft,
                    overflowY: 'auto',
                    maxHeight: '15rem'
                  }
                }}
                // Ensure the menu aligns nicely below the text field
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {options.map(option => {
                  const isSelected = field.value === option.value;
                  return (
                    <MenuItem
                      key={option.value}
                      onClick={() => {
                        field.onChange(option.value);
                        onSelect?.(String(option.value));
                        handleCloseMenu();
                      }}
                      selected={isSelected}
                      sx={{
                        padding: '0.75rem',
                        '&.Mui-selected': {
                          backgroundColor: '#FFF',
                          '&.Mui-focusVisible': { backgroundColor: '#F9F9FA' }
                        },
                        '&.Mui-focusVisible': { backgroundColor: '#F9F9FA' }
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'start'
                        }}
                      >
                        <Typography variant="bodyMediumMedium" color="#344054">
                          {option.label}
                        </Typography>
                        {option.subtitle && (
                          <Typography
                            variant="bodyMediumRegular"
                            color="#98A2B3"
                            sx={{
                              maxWidth: '15rem',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word'
                            }}
                          >
                            {option.subtitle}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ marginLeft: 'auto' }}>
                        <Radio
                          sx={{ width: '1.25rem', height: '1.25rem' }}
                          checked={isSelected}
                          checkedIcon={<RadioChecked />}
                          icon={<RadioUnChecked />}
                        />
                      </Box>
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default MultiPurposeInput;
