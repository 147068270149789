import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import RequestProductReceiptBottomNav from '../nav/bottomNav';
import {
  FormValues,
  RequestProductReceiptContext
} from '../context/requestProductReceiptContext';
import { Resolver, useForm } from 'react-hook-form';
import { facilityDetailsValidator } from '../validator/facilityDetailsValidator';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormDateInput, FormInput, FormSelect } from 'components/form';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as NairaIcon } from 'assets/svg/nairaIcon.svg';
import { fontFamily2, theme } from 'themes/theme';

const timeUnits = [
  { title: 'Days', value: 'days' },
  { title: 'Weeks', value: 'weeks' },
  { title: 'Months', value: 'months' }
];

const convertToDays = (value: string, unit: string) => {
  if (!value) return '';
  const num = parseInt(value, 10);
  if (isNaN(num)) return '';
  switch (unit) {
    case 'weeks':
      return num * 7;
    case 'months':
      return num * 30;
    default:
      return num;
  }
};

const FacilityDetails = () => {
  const { data, updateFormData, setFormState } = useContext(
    RequestProductReceiptContext
  );
  const {
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      facility_amount: data.facility_amount || '',
      repayment_schedule_value: data.repayment_schedule_value || '',
      repayment_schedule_unit: data.repayment_schedule_unit || 'days',
      repayment_schedule_days: data.repayment_schedule_days || '',
      guarantee_fee: data.guarantee_fee || '',
      subsequent_fee: data.subsequent_fee || '',
      tenor: data.tenor || ''
    },
    resolver: yupResolver(
      facilityDetailsValidator
    ) as unknown as Resolver<FormValues>
  });

  const value = watch('repayment_schedule_value');
  const unit = watch('repayment_schedule_unit');
  const tenor = watch('tenor');
  const guarantee_fee = watch('guarantee_fee');
  const subsequent_fee = watch('subsequent_fee');
  const facility_amount = watch('facility_amount');

  const formIsComplete =
    Boolean(facility_amount) &&
    Boolean(value) &&
    Boolean(unit) &&
    Boolean(tenor) &&
    Boolean(guarantee_fee) &&
    Boolean(subsequent_fee);

  const facilityDetailsFormValues = watch();

  useEffect(() => {
    const daysEquivalent = convertToDays(value, unit);
    setValue('repayment_schedule_days', daysEquivalent.toString());
    updateFormData({
      repayment_schedule_value: value,
      repayment_schedule_days: daysEquivalent.toString(),
      repayment_schedule_unit: unit,
      tenor: facilityDetailsFormValues.tenor,
      facility_amount: facilityDetailsFormValues.facility_amount,
      subsequent_fee: facilityDetailsFormValues.subsequent_fee,
      guarantee_fee: facilityDetailsFormValues.guarantee_fee
    });
  }, [value, unit, facilityDetailsFormValues]);

  useEffect(() => {
    setFormState(isValid);
  }, [isValid]);

  return (
    <>
      <Box
        sx={{
          width: '31rem',
          marginBottom: '6.25rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0.0625rem solid #EAECF0',
          borderRadius: '0.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            background: '#fff'
          }}
        >
          <Stack
            sx={{ padding: '1rem', borderBottom: '.063rem solid #F5F6F7' }}
          >
            <Typography variant="h6Bold" color="#475467">
              Bank Guarantee
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              Enter details for this facility
            </Typography>
          </Stack>

          <Box padding=".75rem">
            <Stack direction="column" spacing={0}>
              <FormInput<FormValues>
                startAdornment={
                  <SvgWrapper
                    icon={NairaIcon}
                    height="1.25rem"
                    width="1.25rem"
                  />
                }
                control={control}
                name="facility_amount"
                label="FACILITY AMOUNT"
              />

              <Typography
                variant="bodyMediumRegular"
                sx={{
                  fontFamily: fontFamily2,
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  lineHeight: '1.05rem',
                  letterSpacing: '-0.015rem',
                  color: `${theme.palette.common.labelColor}`,
                  mb: '0.17rem',
                  paddingBottom: '0rem'
                }}
              >
                REPAYMENT SCHEDULE
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  mb: '-1.5rem'
                }}
              >
                <Box width="50%">
                  <FormInput<FormValues>
                    control={control}
                    name="repayment_schedule_value"
                    label=""
                    placeholder="e.g 12"
                  />
                </Box>
                <Box mt="0.25rem" width="50%">
                  <FormSelect<FormValues>
                    control={control}
                    name="repayment_schedule_unit"
                    label=""
                    options={timeUnits}
                  />
                </Box>
              </Box>
              <Typography variant="bodySmallMedium" color="gray" mb={'1.5rem'}>
                Equivalent: {watch('repayment_schedule_days') || '0'} days
              </Typography>

              <FormInput<FormValues>
                control={control}
                name="guarantee_fee"
                label="GUARANTEE FEE"
              />

              <FormInput<FormValues>
                control={control}
                name="subsequent_fee"
                label="SUBSEQUENT FEE"
              />

              <FormDateInput<FormValues>
                control={control}
                name="tenor"
                label="TENOR"
                format="YYYY-MM-DD"
                disablePast
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        <RequestProductReceiptBottomNav
          nextButtonLabel="Next"
          isNextDisabled={!formIsComplete}
        />
      </Box>
    </>
  );
};
export default FacilityDetails;
