import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Tabs as MUITabs,
  Tab as MUITab
} from '@mui/material';
import DistributorLenders from './drawers/distributorLenders';
import DistributorTable from './tables/distributorTable';
import PickupRequestTable from '../../pickup/pickupRequestTable';

const tabList = ['Pickup request', 'Distributor list'];

const DistributorsRegisteredToMerchant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTabIndex];
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentTabIndex(newTabIndex);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.75rem'
          }}
        >
          <Stack gap=".25rem" width="inherit">
            <Typography
              variant="h6Bold"
              color="#475467"
              sx={{ width: '10.75rem' }}
            >
              Distributors
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: '23rem' }}
            >
              See all Distributors and Pickups
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          width: '100%',
          top: '5.3rem',
          left: '0',
          zIndex: 2,
          pl: '1.25rem',
          backgroundColor: 'white',
          position: 'absolute'
        }}
      >
        <MUITabs
          orientation="horizontal"
          value={Math.max(0, currentTabIndex)}
          onChange={(e, value) => handleChange(value)}
          sx={{
            borderBottom: 1,
            borderColor: '#F5F6F7',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {tabList.map(tabId => (
            <MUITab
              key={tabId}
              label={
                <Typography variant="bodyMediumMedium">{tabId}</Typography>
              }
            />
          ))}
        </MUITabs>
      </Box>

      <Box
        role="tabpanel"
        hidden={!isActiveTab('Pickup request')}
        sx={{ width: '100%', marginTop: '4.3rem' }}
      >
        <PickupRequestTable />
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('Distributor list')}
        sx={{ width: '100%', marginTop: '4.3rem' }}
      >
        <DistributorLenders
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
        <DistributorTable setIsOpen={setIsOpen} />
      </Box>
    </Box>
  );
};

export default DistributorsRegisteredToMerchant;
