import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Divider,
  Typography,
  Stack,
  SvgIcon,
  Avatar
} from '@mui/material';

import { ReactComponent as PlusCircle } from 'assets/svg/plus-circle.svg';
import { ReactComponent as MinusCircle } from 'assets/svg/minus-circle.svg';
import { ReactComponent as Trash } from 'assets/svg/trashSolid.svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';

import { Accordion } from 'components/accordion';
import {
  FormInput,
  FormSelect,
  FormSelectOption,
  FormTextArea
} from 'components/form';

import { useForm } from 'react-hook-form';
import { SvgWrapper } from 'components/svg';

import { ReactComponent as VerticalLineIcon } from 'assets/custom-svg/vertical-line-circle.svg';
import { ReactComponent as ZapFastIcon } from 'assets/svg/zap-fast.svg';

import { shadowsTheme } from 'themes/shadows';
import AddStep from 'assets/png/workflow-builder/add-step.png';
import { WorkflowBuilderContext } from './context/builderContext';

import { colorTheme } from 'themes/colors';
import _ from 'lodash';
import { Button } from 'components/button';
import { useGetRolesQuery } from 'services/api/permissionsApi';
import { useGetTeamsQuery } from 'services/api/teamApi';
import { RolesApiData } from 'services/types/permissions';
import { TeamApiData } from 'services/types/teams';
import { useAppSelector } from 'services/hook';
import dayjs from 'dayjs';

type AccordionFormValues = {
  ownedByRole: string;
  ownedByTeam: string;
  completion_time: string;
  completion_time_time: string;
  completion_time_interval: string;
};

interface FormValues extends AccordionFormValues {
  title: string;
  description: string;
}

type AccordionData = {
  title: string;
  subtitle: string;
  formName: keyof AccordionFormValues;
  formOptions: FormSelectOption[];
  getSelectedValue?: string;
};

type WorkflowBuilderStepProps = {
  renderAddButton?: boolean;
  index: number;
  title: string;
  description: string;
  ownedByRole: string;
  ownedByTeam: string;
  completion_time: string;
  completion_time_time: string;
  completion_time_interval: string;
};

const WorkflowBuilderStep: React.FC<WorkflowBuilderStepProps> = ({
  renderAddButton,
  index,
  title,
  description,
  ownedByRole,
  ownedByTeam,
  completion_time,
  completion_time_time,
  completion_time_interval
}) => {
  const { FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP } = useAppSelector(
    state => state.featureFlags
  );

  const { data: roles } = useGetRolesQuery({});
  const { data: teams } = useGetTeamsQuery({});

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedAccordion, setSelectedAccordion] = useState<string>('');
  const [completedAccordion, setCompletedAccordion] = useState<string>('');
  const [completionTimeAccordion, setCompletionTimeAccordion] =
    useState<string>('');

  const roleOptions = useMemo(() => {
    const emptyRoleOptions: FormSelectOption[] = [];
    if (roles) {
      return roles.map(role => ({
        title: role.name,
        value: role.role_id
      }));
    }
    return emptyRoleOptions;
  }, [roles]);

  const teamOptions = useMemo(() => {
    const emptyteamOptions: FormSelectOption[] = [];
    if (teams) {
      return teams.map(team => ({
        title: team.name,
        value: team.team_id
      }));
    }
    return emptyteamOptions;
  }, [teams]);

  const { control, getValues, setValue, watch } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      title: title,
      description: description,
      ownedByRole: ownedByRole,
      ownedByTeam: ownedByTeam,
      completion_time: completion_time,
      completion_time_time: completion_time_time,
      completion_time_interval: completion_time_interval
    }
  });
  const { workflowSteps, setWorkflowSteps, addNewStep } = useContext(
    WorkflowBuilderContext
  );

  const saveStepField = (field: keyof FormValues) => {
    const newSteps = _.cloneDeep(workflowSteps);
    const currentStep = newSteps[index];
    currentStep[field] = getValues(field);
    setWorkflowSteps(newSteps);
    if (field === 'ownedByRole' || field === 'ownedByTeam') {
      setSelectedAccordion(field);
    }
  };

  const clearStep = () => {
    const newSteps = _.cloneDeep(workflowSteps);
    newSteps[index] = {
      title: `Step ${index + 1}`,
      description: 'Enter a description',
      ownedByRole: '',
      ownedByTeam: '',
      completion_time: '',
      completion_time_time: '',
      completion_time_interval: ''
    };
    setWorkflowSteps(newSteps);
    setSelectedAccordion('');
    setCompletedAccordion('');
    setCompletionTimeAccordion('');
  };

  const deleteStep = () => {
    const newSteps = _.cloneDeep(workflowSteps);
    newSteps.splice(index, 1);
    setWorkflowSteps(newSteps);
  };

  const markAsCompleted = (name: string) => {
    setCompletedAccordion(name);
    setSelectedAccordion('');
  };

  const timeValue = watch('completion_time_time');
  const intervalValue = watch('completion_time_interval');

  const toSeconds = (
    value: number,
    unit: 'weeks' | 'days' | 'months' | 'hours'
  ): number => {
    return dayjs().add(value, unit).diff(dayjs(), 'seconds');
  };

  useEffect(() => {
    if (timeValue !== '' && intervalValue !== '') {
      setValue(
        'completion_time',
        toSeconds(
          parseFloat(timeValue),
          intervalValue as 'weeks' | 'days' | 'months' | 'hours'
        ).toString()
      );
      saveStepField('completion_time');
    }
  }, [timeValue, intervalValue]);

  const accordionData: AccordionData[] = [
    {
      title: 'Assign Role',
      subtitle: 'Select role needed to approve or manage a step',
      formName: 'ownedByRole',
      formOptions: roleOptions,
      getSelectedValue: roles?.find(
        (el: RolesApiData) => el.role_id === getValues('ownedByRole')
      )?.name
    }
  ];

  FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP &&
    accordionData.push({
      title: 'Assign Team',
      subtitle: 'Select team needed to approve or manage a step',
      formName: 'ownedByTeam',
      formOptions: teamOptions,
      getSelectedValue: teams?.find(
        (el: TeamApiData) => el.team_id === getValues('ownedByTeam')
      )?.name
    });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          border: '0.0625rem solid #F2F4F7',
          background: '#FFF',
          padding: '1rem',
          display: 'flex',
          maxWidth: '32rem',
          minWidth: '32rem',
          flexShrink: 1,
          flexGrow: 1,
          flexDirection: 'column',
          margin: 'auto',
          borderRadius: '0.75rem',
          boxShadow: shadowsTheme.xxSmallShadowSoft
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingBottom: '0.75rem',
            cursor: 'pointer'
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75rem',
              width: '100%',
              flexGrow: 0
            }}
          >
            <Box
              sx={{
                backgroundColor: '#EBF1FD',
                borderRadius: '0.438rem',
                width: '2.25rem',
                height: '2.25rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SvgWrapper
                icon={ZapFastIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  fill: colorTheme.primary,
                  stroke: 'none'
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '60%'
              }}
            >
              <Typography
                noWrap
                sx={{ width: '100%' }}
                variant="bodyMediumSemibold"
                color="#1D2939"
              >
                {title}
              </Typography>
              <Typography
                noWrap
                sx={{ width: '100%' }}
                variant="bodyMediumRegular"
                color="#667085"
              >
                {description}
              </Typography>
            </Box>
            <Button
              styleOverrides={{ marginLeft: 'auto', border: 'none' }}
              text="Clear"
              transparent
              color="error"
              size="sm"
              onClick={() => {
                clearStep();
              }}
            />
            <Box
              onClick={() => {
                deleteStep();
              }}
              component="span"
              sx={{ cursor: 'pointer' }}
            >
              <SvgWrapper
                icon={Trash}
                width="1.5rem"
                height="1.5rem"
                styleOverrides={{
                  opacity: 0.9,
                  fill: '#475467'
                }}
              />
            </Box>
            <SvgWrapper
              icon={!isOpen ? ChevronDown : ChevronRight}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#000000'
              }}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            color: '#F2F4F7',
            height: '0.125em',
            opacity: 0.4,
            mb: isOpen ? '1rem' : '0rem'
          }}
        />
        {!isOpen && (
          <Box sx={{ marginTop: '1rem' }}>
            <form>
              <FormInput<FormValues>
                control={control}
                name="title"
                label="TITLE"
                onChange={() => saveStepField('title')}
              />
              <FormTextArea<FormValues>
                control={control}
                name="description"
                label="DESCRIPTION"
                onChange={() => saveStepField('description')}
                minRows={3}
                maxRows={5}
              />
            </form>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor:
              completionTimeAccordion === 'completion_time'
                ? '#ffffff'
                : '#FAFBFB',
            borderRadius: '0.75rem',
            border: '0.0625rem solid #F5F6F',
            width: '100%',
            mb: '.75rem'
          }}
        >
          {completionTimeAccordion !== 'completion_time' ? (
            <Accordion
              summary={
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0.75rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium">
                    Add Completion Time
                  </Typography>
                  <Typography variant="bodySmallMedium" color="#98A2B3">
                    Choose completion time for this step
                  </Typography>
                </Stack>
              }
              expandIcon={
                <SvgWrapper
                  icon={PlusCircle}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{ fill: 'none', stroke: '#475467' }}
                />
              }
              closeIcon={
                <SvgWrapper
                  icon={MinusCircle}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{
                    fill: '#98A2B3',
                    stroke: '#FFFFFF'
                  }}
                />
              }
            >
              <Stack
                direction="column"
                padding="0.75rem 0rem"
                alignItems="center"
              >
                <>
                  <FormInput<FormValues>
                    name={'completion_time_time'}
                    label="Choose time"
                    placeholder={timeValue}
                    onChange={() => saveStepField('completion_time_time')}
                    control={control}
                  />
                  <FormSelect<FormValues>
                    control={control}
                    name={'completion_time_interval'}
                    label=""
                    options={[
                      {
                        title: 'Hour(s)',
                        value: 'hours'
                      },
                      {
                        title: 'Day(s)',
                        value: 'days'
                      },
                      {
                        title: 'Week(s)',
                        value: 'weeks'
                      },
                      {
                        title: 'Month(s)',
                        value: 'months'
                      }
                    ]}
                    onChange={() => saveStepField('completion_time_interval')}
                    placeholder={intervalValue}
                    renderSelected={value => value}
                  />
                </>

                <Divider
                  sx={{
                    height: '0.125em',
                    opacity: 0.4,
                    width: '100%'
                  }}
                />

                <Button
                  text={!getValues('completion_time') ? 'Add' : 'Done'}
                  color="grey"
                  transparent
                  size="md"
                  onClick={() => setCompletionTimeAccordion('completion_time')}
                  styleOverrides={{
                    width: '100%',
                    mt: '1.25rem',
                    borderColor: '#F2F4F7'
                  }}
                  disabled={!getValues('completion_time')}
                />
              </Stack>
            </Accordion>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '0.75rem',
                width: '100%',
                flexGrow: 0
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '60%',
                  gap: '.5rem'
                }}
              >
                <SvgWrapper
                  icon={Dot}
                  width=".5rem"
                  height=".5rem"
                  styleOverrides={{
                    fill: colorTheme.primary,
                    stroke: colorTheme.primary,
                    borderRadius: '50%'
                  }}
                />
                <Typography
                  sx={{ width: '100%' }}
                  variant="bodyMediumMedium"
                  color="#475467"
                >
                  Add Completion Time
                </Typography>
              </Box>

              <Button
                styleOverrides={{ marginLeft: 'auto', border: 'none' }}
                text="Clear"
                transparent
                color="grey"
                size="sm"
                onClick={() => {
                  setCompletionTimeAccordion('');
                  setValue('completion_time_time', '');
                  setValue('completion_time_interval', '');
                  setValue('completion_time', '');
                }}
              />
            </Box>
          )}
        </Box>

        {accordionData.map((element, index) => {
          return (
            <Box
              key={index}
              sx={{
                backgroundColor:
                  completedAccordion === element.formName
                    ? '#ffffff'
                    : '#FAFBFB',
                borderRadius: '0.75rem',
                border: '0.0625rem solid #F5F6F',
                width: '100%',
                mb: accordionData.length === 1 ? '0rem' : '.75rem'
              }}
            >
              {completedAccordion !== element.formName ? (
                <Accordion
                  disabled={
                    (selectedAccordion !== element.formName &&
                      selectedAccordion !== '') ||
                    completedAccordion !== ''
                  }
                  summary={
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '0.75rem'
                      }}
                    >
                      <Typography variant="bodyMediumMedium">
                        {element.title}
                      </Typography>
                      <Typography variant="bodySmallMedium" color="#98A2B3">
                        {element.subtitle}
                      </Typography>
                    </Stack>
                  }
                  expandIcon={
                    <SvgWrapper
                      icon={PlusCircle}
                      width="1.25rem"
                      height="1.25rem"
                      styleOverrides={{ fill: 'none', stroke: '#475467' }}
                    />
                  }
                  closeIcon={
                    <SvgWrapper
                      icon={MinusCircle}
                      width="1.25rem"
                      height="1.25rem"
                      styleOverrides={{
                        fill: '#98A2B3',
                        stroke: '#FFFFFF'
                      }}
                    />
                  }
                >
                  <Stack
                    direction="column"
                    padding="0.75rem 0rem"
                    alignItems="center"
                  >
                    <FormSelect<FormValues>
                      name={element.formName}
                      options={element.formOptions}
                      label=""
                      onChange={() => saveStepField(element.formName)}
                      renderSelected={value => value}
                      control={control}
                    />

                    {getValues(element.formName) && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                        mb="1rem"
                      >
                        <Typography variant="bodyMediumMedium" color="#475467">
                          {element.getSelectedValue}
                        </Typography>
                        <Box
                          onClick={() => {
                            setValue(element.formName, '');
                            saveStepField(element.formName);
                            setSelectedAccordion('');
                          }}
                          component="span"
                          sx={{ cursor: 'pointer' }}
                        >
                          <SvgWrapper
                            icon={Trash}
                            width="1rem"
                            height="1rem"
                            styleOverrides={{
                              opacity: 0.8,
                              fill: '#98A2B3'
                            }}
                          />
                        </Box>
                      </Stack>
                    )}

                    <Divider
                      sx={{
                        height: '0.125em',
                        opacity: 0.4,
                        width: '100%'
                      }}
                    />

                    <Button
                      text={!getValues(element.formName) ? 'Assign' : 'Done'}
                      color="grey"
                      transparent
                      size="md"
                      onClick={() => markAsCompleted(element.formName)}
                      styleOverrides={{
                        width: '100%',
                        mt: '1.25rem',
                        borderColor: '#F2F4F7'
                      }}
                      disabled={!getValues(element.formName)}
                    />
                  </Stack>
                </Accordion>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '0.75rem',
                    width: '100%',
                    flexGrow: 0
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '60%',
                      gap: '.5rem'
                    }}
                  >
                    <SvgWrapper
                      icon={Dot}
                      width=".5rem"
                      height=".5rem"
                      styleOverrides={{
                        fill: colorTheme.primary,
                        stroke: colorTheme.primary,
                        borderRadius: '50%'
                      }}
                    />
                    <Typography
                      sx={{ width: '100%' }}
                      variant="bodyMediumMedium"
                      color="#475467"
                    >
                      {element.title}
                    </Typography>
                  </Box>

                  <Button
                    styleOverrides={{ marginLeft: 'auto', border: 'none' }}
                    text="Clear"
                    transparent
                    color="grey"
                    size="sm"
                    onClick={() => {
                      setCompletedAccordion('');
                      setSelectedAccordion('');
                      setValue(element.formName, '');
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SvgIcon component={VerticalLineIcon} inheritViewBox />
        {renderAddButton && (
          <Avatar
            src={AddStep}
            sx={{
              mt: '0.0625rem',
              cursor: 'pointer',
              '&:hover': { opacity: 0.5 }
            }}
            onClick={addNewStep}
          />
        )}
      </Box>
    </Box>
  );
};
export default WorkflowBuilderStep;
