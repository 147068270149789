import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import google from 'assets/svg/google.svg';
import Divider from 'components/common/divider';
import { FormCheckBox, FormInput, FormPassword } from 'components/form';
import ShowInfo from 'components/info/showInfo';
import { logInAuthValidator } from 'pages/auth/validators/authFormValidators';
import React, { useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginUserMutation } from 'services/api/authApi';
import { api } from 'services/api/baseApi';
import { useAppDispatch } from 'services/hook';
import { setAppShouldLoad } from 'services/slices/appSlice';
import { setUserLogin } from 'services/slices/authSlice';
import { fontFamily2, theme } from 'themes/theme';
import { isProductionEnvironment } from 'utilities/environment';

type FormValues = {
  email: string;
  password: string;
  remember: boolean;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const redirect_url = searchParams.get('redirect');

  const [loginUser, { data, isSuccess, isError, error }] =
    useLoginUserMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      remember: false
    },
    resolver: yupResolver(logInAuthValidator) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await loginUser({
      password: data.password,
      email: data.email,
      remember: data.remember
    });
  };

  interface MyError {
    data:
      | {
          non_field_errors: string[];
        }
      | string;
    status?: number;
  }

  useEffect(() => {
    if (data && isSuccess) {
      sessionStorage.clear();
      dispatch(api.util.resetApiState());
      dispatch(
        setUserLogin({
          isAuthenticated: true,
          userId: data.userid,
          organizationId: data.organization_id,
          organizationType: data.organization_type,
          membershipType: data.membership_type,
          individualOrganization: data.individual_organization,
          supplyChainProfileId: data.supply_chain_profile_id,
          devAuthToken:
            !isProductionEnvironment() && data.token ? data.token : null,
          branch: {
            branchId: data.branch.branch_id,
            branchName: data.branch.name
          },
          organizationConfig: {
            enableBranches: data.organization_config.enable_branches,
            enableTeams: data.organization_config.enable_teams,
            requirePrcBranchAssignmentWorkflow:
              data.organization_config.require_prc_branch_assignment_workflow,
            requirePrcEndorsementWorkflow:
              data.organization_config.require_prc_endorsement_workflow,
            requireRoleRequestApprovalWorkflow:
              data.organization_config.require_role_request_approval_workflow,
            requireProductPublishWorkflow:
              data.organization_config.require_product_publish_workflow
          }
        })
      );
      dispatch(setAppShouldLoad());
      redirect_url ? window.location.replace(redirect_url) : navigate('/');
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        Object.values(myError.data).length > 0
      ) {
        ShowInfo({
          message: `${Object.values(myError.data)[0].join(', ')}`,
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      } else if (typeof myError.data === 'string') {
        ShowInfo({
          message: `${myError.data}`,
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      } else {
        ShowInfo({
          message: 'Login unsuccessful!',
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      }
    }
  }, [isSuccess, isError]);

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem 2rem 6rem 2rem'
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            maxWidth: '32rem',
            padding: '2.5rem 1.5rem',
            backgroundColor: `${theme.palette.common.transparent}`,
            borderRadius: '.75rem'
          }}
        >
          <Box sx={{ textAlign: 'center', mb: '1.5rem' }}>
            <Typography
              variant="h5"
              sx={{ color: `${theme.palette.common.subheading}` }}
            >
              Welcome Back
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              sx={{ color: `${theme.palette.common.labelColor}` }}
            >
              Enter your credentials to access your account
            </Typography>
          </Box>

          <Button
            variant="outlined"
            sx={{
              padding: '0.625rem 1rem',
              border: `.063rem solid ${theme.palette.common.cardBorder}`,
              backgroundColor: `${theme.palette.common.white}`,
              mb: '1.5rem',
              width: '100%'
            }}
          >
            <Stack direction="row" gap=".75rem">
              <Box component="img" src={google} />
              <Typography
                sx={{
                  color: `${theme.palette.common.subheading}`,
                  fontSize: '1rem',
                  fontFamily: fontFamily2,
                  fontWeight: 600
                }}
              >
                Sign in with SSO
              </Typography>
            </Stack>
          </Button>

          <Divider mb="1.5rem" fontWeight={400} />

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormInput<FormValues>
              control={control}
              label="EMAIL"
              name="email"
            />

            <FormPassword<FormValues>
              control={control}
              label="PASSWORD"
              name="password"
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormCheckBox<FormValues>
                control={control}
                label="Remember Me"
                name="remember"
              />

              <Link
                to="/forgot-password"
                style={{
                  textDecoration: 'none',
                  color: `${theme.palette.common.blueColor}`
                }}
              >
                <Typography variant="bodyMediumMedium">
                  Forgot Password?
                </Typography>
              </Link>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              disabled={!isDirty || !isValid || isSubmitting}
              sx={{
                padding: '0.75rem 1rem',
                color: 'white',
                width: '100%',
                mt: '1.5rem',
                fontSize: '1rem'
              }}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={22}
                  sx={{
                    color: 'common.white',
                    margin: '0rem .625rem'
                  }}
                />
              ) : (
                'Log In'
              )}
            </Button>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  color: ' #98A2B3'
                }}
              >
                Don&apos;t have an account?{' '}
                <Link
                  to="/onboarding"
                  style={{
                    textDecoration: 'none',
                    color: `${theme.palette.common.blueColor}`
                  }}
                >
                  <Typography
                    variant="bodyMediumMedium"
                    sx={{
                      marginLeft: '0.1875rem'
                    }}
                  >
                    Sign up
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </form>
        </Paper>
      </Box>
    </Stack>
  );
};

export default Login;
