import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DateRangeType, Nav } from './chartsNav';
import BarChart from '../../../../components/charts/barchart';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import { useGetClosedProductReceiptsHoldersQuery } from 'services/api/analyticsApi';
import { formattedDate } from 'utilities/helperFunc/formatter';

const defaultDateRange = getDateRange('This Month');

const TopCustomersChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: productReceiptHoldersData } =
    useGetClosedProductReceiptsHoldersQuery({
      params: {
        start: dateRange.startDate
          ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
          : '2024-01-01',
        ...(dateRange.endDate && {
          end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
        }),
        holder_type: 'distributor',
        limit: '10'
      }
    });

  const labels = productReceiptHoldersData
    ? productReceiptHoldersData.map(item => item.profile.name)
    : [];
  const chartDataset = [
    {
      data: productReceiptHoldersData
        ? productReceiptHoldersData.map(item => Number(item.total_value))
        : [],
      label: 'Amount'
    }
  ];

  return (
    <Box
      sx={{
        width: '48%',
        height: '31.25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1rem'
      }}
    >
      <Nav
        header="Top Customers"
        caption="Customers with most endorsements"
        setDateRange={setDateRange}
        showButton={true}
      />
      <BarChart labels={labels} datasets={chartDataset} horizontal={true} />
    </Box>
  );
};

export default TopCustomersChart;
