import React from 'react';
import { Stack, Toolbar } from '@mui/material';
import { ReactComponent as chevronLeft } from 'assets/svg/chevronLeft.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button';

const PaymentBottomNav: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        height: '100%',
        borderTop: '0.063rem solid #F5F6F7',
        background: '#fff'
      }}
    >
      <Stack width="100%" direction="row">
        <Button
          icon={chevronLeft}
          iconPosition="start"
          transparent
          size="md"
          text="Exit Product Receipt Payment"
          color="primary"
          styleOverrides={{
            color: '#2C59B0',
            '& svg': {
              stroke: '#2C59B0'
            }
          }}
          onClick={() => {
            navigate('/');
          }}
        />
      </Stack>
    </Toolbar>
  );
};

export default PaymentBottomNav;
