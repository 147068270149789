import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { theme } from 'themes/theme';
import LineChart from '../../../../components/charts/linechart';
import { useGetAggregateProductReceiptsQuery } from 'services/api/analyticsApi';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { DateRangeType, Nav } from './chartsNav';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

const defaultDateRange = getDateRange('This Month');
const TotalPrcIssuedChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: totalPrcValue } = useGetAggregateProductReceiptsQuery({
    params: {
      start: dateRange.startDate
        ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
        : '2024-01-01',
      granularity: dateRange.granularity,
      date_logic: 'endorsed',
      ...(dateRange.endDate && {
        end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
      })
    }
  });

  const labels = totalPrcValue ? totalPrcValue.map(item => item.date) : [];
  const chartDataset = [
    {
      data: totalPrcValue ? totalPrcValue.map(item => item.value) : [],
      label: 'Total Value Issued',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    }
  ];

  return (
    <Box
      sx={{
        width: '48%',
        height: '31.25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1rem'
      }}
    >
      <Nav
        header="Product Receipts Issued"
        setDateRange={setDateRange}
        showButton={true}
      />
      <LineChart labels={labels} datasets={chartDataset} />
    </Box>
  );
};

export default TotalPrcIssuedChart;
