import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Table } from 'components/table';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/terminalEmptyStateIcon.svg';
import { SvgWrapper } from 'components/svg';

interface PaymentOverviewTableData {
  prcCode: string;
  date: string;
  merchant: string;
  product: string;
  totalValue: string;
  receipt?: string;
}

interface PaymentOverviewProps {
  data: PaymentOverviewTableData[];
  isLoading: boolean;
}

export const demoPaymentOverviewData: PaymentOverviewTableData[] = [
  {
    prcCode: 'DANG-1267',
    date: '31st Aug. 2024',
    merchant: 'Dangote PLC 1',
    product: 'Cement bags 1',
    totalValue: '200,000,000'
  },
  {
    prcCode: 'DANG-1268',
    date: '27th Aug. 2024',
    merchant: 'Dangote PLC 2',
    product: 'Cement bags 2',
    totalValue: '160,000,000'
  },
  {
    prcCode: 'DANG-1269',
    date: '31st Aug. 2024',
    merchant: 'Dangote PLC 2',
    product: 'Cement bags 3',
    totalValue: '120,000,000'
  }
];

const columnHelper = createColumnHelper<PaymentOverviewTableData>();
export const pendingColumns = [
  columnHelper.accessor('prcCode', {
    header: 'Product ID',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('date', {
    header: 'Date',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('merchant', {
    header: 'Merchant',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('product', {
    header: 'Product',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('totalValue', {
    header: 'Total Value',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('receipt', {
    header: 'RECEIPT',
    cell: () => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        style={{ textDecoration: 'underline' }}
      >
        Receipt
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<PaymentOverviewTableData>[];

const PaymentOverviewTable = ({ data, isLoading }: PaymentOverviewProps) => {
  const pendingPayments = data;

  const updatedPendingPaymentsList = useMemo(() => {
    return pendingPayments.map((pendingItem: PaymentOverviewTableData) => ({
      prcCode: pendingItem.prcCode || 'N/A',
      date: pendingItem.date || 'N/A',
      merchant: pendingItem.merchant || 'N/A',
      product: pendingItem.product || 'N/A',
      totalValue: pendingItem.totalValue || '$0'
    }));
  }, [pendingPayments]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Table<PaymentOverviewTableData>
        hover
        columns={pendingColumns}
        data={updatedPendingPaymentsList}
        onClickRow={(rowData: PaymentOverviewTableData) =>
          console.log(`Hi from row ${rowData.merchant}`)
        }
        showSearch={false}
        enableFilter={false}
        loadingTable={isLoading}
        title="Payment Overview"
        subtitle="List of pending and completed transactions"
        emptyDataTitle="No Payment Overview"
        emptyDataSubtitle="You don’t have any payment overview yet"
        emptyDataIcon={
          <SvgWrapper icon={EmptyStateIcon} width="9rem" height="9rem" />
        }
      />
    </Box>
  );
};

export default PaymentOverviewTable;
