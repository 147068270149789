import React from 'react';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as TagItemIcon } from 'assets/svg/tag.svg';
import { ReactComponent as EditIcon } from 'assets/svg/prcEdit.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/prcDelete.svg';
import { ReactComponent as TagIcon } from 'assets/svg/tagIcon.svg';
import { FormAutocomplete, FormInput, FormSelect } from 'components/form';
import MultiPurposeInput from 'components/form/formMultiPurposeInput';
import { SvgWrapper } from 'components/svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useGetProductsQuery } from 'services/api/productApi';
import {
  FormValues,
  RequestProductReceiptContext
} from '../context/requestProductReceiptContext';
import RequestProductReceiptBottomNav from '../nav/bottomNav';
import { productReceiptDetailsValidator } from '../validator/productReceiptDetailsValidator';
import { Button } from 'components/button';
import { useGetProductReceiptTagsQuery } from 'services/api/productReceiptApi';

type ProductReceiptDetailsProps = {
  customerOptions: { title: string; value: string }[];
  merchantOptions: { title: string; value: string }[];
};

const generateBatches = (total: number, desiredBatchCount = 3): number[] => {
  const batchCount = total < desiredBatchCount ? total : desiredBatchCount;
  if (batchCount === 0) return [];
  const base = Math.floor(total / batchCount);
  const remainder = total - base * batchCount;
  const batches = Array(batchCount).fill(base);
  batches[batchCount - 1] += remainder;
  return batches;
};

const ProductReceiptDetails = ({
  customerOptions,
  merchantOptions
}: ProductReceiptDetailsProps) => {
  const { data, updateFormData, setFormState } = useContext(
    RequestProductReceiptContext
  );

  const { data: tagsData } = useGetProductReceiptTagsQuery();
  const dynamicTagTypeOptions = useMemo(() => {
    return (
      tagsData?.keys?.map(key => ({
        value: key,
        label: key
      })) || []
    );
  }, [tagsData]);

  const dynamicTagNameOptions = useMemo(() => {
    return (
      tagsData?.values?.map(value => ({
        value: value,
        label: value
      })) || []
    );
  }, [tagsData]);

  const {
    control,
    watch,
    setValue,
    register,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      customer_name: data.customer_name,
      merchant: data.merchant,
      product: data.product,
      total_quantity: data.total_quantity,
      amount: data.amount,
      tags: data.tags || [],
      tagsType: data.tagsType,
      tagsName: data.tagsName,
      facility_type: data.facility_type || '',
      guarantee_fee: data.guarantee_fee || '',
      subsequent_fee: data.subsequent_fee || '',
      tenor: data.tenor || ''
    },
    resolver: yupResolver(
      productReceiptDetailsValidator
    ) as unknown as Resolver<FormValues>
  });

  const [showAddTagButton, setShowAddTagButton] = useState(false);

  // Helper: return merchant ID based on the selected merchant name.
  const getMerchantId = (merchantName: string): string => {
    const found = merchantOptions.find(m => m.title === merchantName);
    return found ? found.value : merchantName;
  };

  const values = watch();
  const selectedMerchantName = watch('merchant');
  const tagsType = watch('tagsType');
  const tagsName = watch('tagsName');
  const merchantId = getMerchantId(selectedMerchantName);

  useEffect(() => {
    // When customer_name changes, update customerID accordingly
    const selectedCustomer = customerOptions.find(
      opt => opt.title === values.customer_name
    );
    updateFormData({
      customer_name: values.customer_name,
      customerID: selectedCustomer ? selectedCustomer.value : ''
    });
  }, [values.customer_name, customerOptions, updateFormData]);

  // Update merchantID when merchant changes
  useEffect(() => {
    const selectedMerchant = merchantOptions.find(
      opt => opt.title === values.merchant
    );
    updateFormData({
      merchant: values.merchant,
      merchantID: selectedMerchant ? selectedMerchant.value : ''
    });
  }, [values.merchant, merchantOptions, updateFormData]);

  const formIsComplete =
    Boolean(values.customer_name) &&
    Boolean(values.merchant) &&
    Boolean(values.product) &&
    Boolean(values.total_quantity) &&
    Boolean(values.facility_type);

  // Use the merchantId in your products query.
  const { data: productData } = useGetProductsQuery(merchantId, {
    skip: !merchantId
  });

  const productOptions = useMemo(() => {
    if (!productData) return [];
    return productData.map(product => {
      return {
        title: product.name,
        value: product.variants?.[0]?.product_variant_id,
        price: product.variants?.[0]?.price, // assumes first variant's price
        unit: product.variants?.[0]?.unit
      };
    });
  }, [productData]);

  useEffect(() => {
    register('tags', { value: [] });
  }, [register]);

  useEffect(() => {
    const selectedProduct = productOptions.find(
      product => product.value === values.product
    );
    updateFormData({
      customer_name: values.customer_name,
      merchant: values.merchant,
      product: values.product,
      productName: selectedProduct ? selectedProduct.title : '',
      unit: selectedProduct ? selectedProduct.unit : '',
      total_quantity: values.total_quantity.replace(/[,₦]/g, ''),
      tags: values.tags || [],
      facility_type: values.facility_type,
      tagsType: values.tagsType,
      tagsName: values.tagsName
    });
    setFormState(isValid);

    // Calculate batches only if the quantity hasn’t been manually edited.
    const parsedQuantity = parseInt(
      values.total_quantity.replace(/[,₦]/g, ''),
      10
    );
    if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
      const batches = generateBatches(parsedQuantity, 3);
      if (!data.isQuantityManual) {
        updateFormData({ quantity: batches.map(String) });
        if (selectedProduct && selectedProduct.price) {
          updateFormData({ productPrice: selectedProduct.price });
          updateFormData({ amount: batches.map(() => selectedProduct.price) });
          updateFormData({
            total_amount: (
              parseFloat(selectedProduct.price) * batches.length
            ).toString()
          });
        }
      }
    }
  }, [
    values,
    isValid,
    updateFormData,
    productOptions,
    setFormState,
    data.isQuantityManual
  ]);

  const handleAddTag = () => {
    const trimmedTagsType = tagsType.trim();
    const trimmedTagsName = tagsName.trim();
    const newTag = { tagsType: trimmedTagsType, tagsName: trimmedTagsName };
    if (
      values.tags?.some(
        tag =>
          tag.tagsType.trim().toLowerCase() === newTag.tagsType.toLowerCase() &&
          tag.tagsName.trim().toLowerCase() === newTag.tagsName.toLowerCase()
      )
    ) {
      return;
    }
    setValue('tags', [...(values.tags || []), newTag], {
      shouldValidate: true
    });
    setValue('tagsType', '');
    setValue('tagsName', '');
    setShowAddTagButton(false);
  };

  const handleEditTag = (index: number) => {
    const tagToEdit = values.tags[index];
    setValue('tagsType', tagToEdit.tagsType);
    setValue('tagsName', tagToEdit.tagsName);
  };

  const handleDeleteTag = (index: number) => {
    const newTags = [...(values.tags || [])];
    newTags.splice(index, 1);
    setValue('tags', newTags);
  };
  return (
    <>
      <Box
        sx={{
          width: '56.25rem',
          marginBottom: '6.25rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0.0625rem solid #EAECF0',
          borderRadius: '0.5rem'
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            margin: 0,
            padding: 0,
            py: 0
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              borderRight: '0.125rem solid #EAECF0'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: 'auto',
                borderRadius: '.75rem 0 0 .75rem',
                background: '#fff'
              }}
            >
              <Stack
                sx={{
                  padding: '1rem',
                  paddingTop: '1.25rem',
                  borderBottom: '.063rem solid #F5F6F7',
                  gap: '.25rem'
                }}
              >
                <Typography variant="h6Bold" color="#475467">
                  Product Receipt Details
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  Input details to begin this process
                </Typography>
              </Stack>

              <Box padding=".75rem">
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  sx={{
                    borderRadius: '0.5rem',
                    border: '0.0625rem solid #F5F6F7',
                    background: 'rgba(102, 112, 133, 0.02)',
                    pb: '0rem',
                    maxHeight: { md: '28rem', lg: '35rem', xl: 'fit-content' },
                    overflow: 'auto'
                  }}
                >
                  <Stack direction="column" width="inherit">
                    <Stack direction="column" padding=".75rem" pb="0rem">
                      <FormAutocomplete<FormValues>
                        control={control}
                        multiple={false}
                        name="customer_name"
                        label="CUSTOMER"
                        options={customerOptions.map(option => option.title)}
                      />

                      <FormAutocomplete<FormValues>
                        control={control}
                        multiple={false}
                        name="merchant"
                        label="MERCHANT"
                        options={merchantOptions.map(option => option.title)}
                      />

                      <FormSelect<FormValues>
                        name="product"
                        options={productOptions}
                        label="PRODUCT"
                        control={control}
                        renderSelected={(value: string) => value}
                      />

                      <FormInput<FormValues>
                        control={control}
                        name="total_quantity"
                        label="TOTAL QUANTITY"
                      />

                      <FormSelect<FormValues>
                        name="facility_type"
                        options={[
                          { title: 'BANK GAURANTEE', value: 'bank_guarantee' }
                        ]}
                        label="SELECT FACILITY TYPE"
                        control={control}
                        renderSelected={(value: string) => value}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: '0rem',
                background: 'rgba(250, 251, 252, 0.99)',
                height: '100%',
                px: '1.5rem',
                pt: '1rem'
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                <TagIcon fill="#ffffff" />
              </Stack>
              <Box
                mb={'1.25rem'}
                sx={{
                  borderBottom: '0.09rem solid #F2F4F7',
                  pb: '.5rem',
                  mb: '0.1875rem'
                }}
              >
                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    fontWeight: 600,
                    display: 'block'
                  }}
                  color="#344054"
                >
                  Add/Create Tags
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Tags help categorize & organize Product Receipts.
                  <br /> Create or select one to tag this receipt.
                </Typography>
              </Box>

              <Box my={2} />

              <MultiPurposeInput
                name="tagsType"
                label="TAG TYPE"
                control={control}
                type="select"
                options={dynamicTagTypeOptions}
                placeholder="Select or enter text"
                onSelect={value => {
                  setShowAddTagButton(
                    value.trim() !== '' && tagsName.trim() !== ''
                  );
                }}
                // When the user types manually in the tagType field:
                onChange={value => {
                  setShowAddTagButton(
                    value.trim() !== '' && tagsName.trim() !== ''
                  );
                }}
                size="medium"
                showTooltip
                tooltipSubText="Select or create a category for this tag (e.g., Branch, Transaction ID)"
              />

              <MultiPurposeInput
                name="tagsName"
                label="TAG NAME"
                control={control}
                type="select"
                options={dynamicTagNameOptions}
                placeholder="Enter tag name"
                onChange={value => {
                  setShowAddTagButton(
                    tagsType.trim() !== '' && value.trim() !== ''
                  );
                }}
                onSelect={value => {
                  setShowAddTagButton(
                    tagsType.trim() !== '' && value.trim() !== ''
                  );
                }}
                showTooltip
                tooltipSubText="Enter the specific value for this tag (e.g., Ojota for Branch, 26378 for Transaction ID"
              />

              {showAddTagButton && (
                <>
                  <Button
                    onClick={handleAddTag}
                    color={'primary'}
                    size="md"
                    outline
                    text=" Add Tag"
                    width="100%"
                    styleOverrides={{
                      mr: '0.5rem',
                      boxShadow: '0px 1.5px 4px -1px rgba(113, 120, 132, 0.5)'
                    }}
                  />
                </>
              )}

              {values.tags?.length > 0 && (
                <Stack spacing={2} mt={2}>
                  {values.tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#F9FAFB',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0.75rem'
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          key={index}
                          sx={{
                            background: '#F5F6F7',
                            borderRadius: '0.375rem',
                            height: '2.25rem',
                            display: 'flex',
                            alignItem: 'center',
                            p: '0.5rem'
                          }}
                        >
                          <Box
                            mr={'0.3125rem'}
                            sx={{
                              display: 'inline'
                            }}
                          >
                            <SvgWrapper
                              icon={TagItemIcon}
                              width=".75rem"
                              height=".75rem"
                              color="##5B5DE3"
                            />
                          </Box>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {`${tag.tagsType} : `}
                          </Typography>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {tag.tagsName}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack direction="row" spacing={1}>
                        <IconButton onClick={() => handleEditTag(index)}>
                          <EditIcon
                            stroke="#667085"
                            height="1.25rem"
                            width="1.25rem"
                          />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteTag(index)}>
                          <DeleteIcon fill="#667085" />
                        </IconButton>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <RequestProductReceiptBottomNav
          nextButtonLabel="Next"
          isNextDisabled={!formIsComplete}
        />
      </Box>
    </>
  );
};
export default ProductReceiptDetails;
