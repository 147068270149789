import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as InfoIcon } from 'assets/svg/infoCircle.svg';
import PaymentSuccessComponent from './paymentSuccessComponent';
import { Button } from 'components/button';

const PaymentSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const transactionDetails = {
    productID: '#1267483',
    merchantName: 'Dangote Limited',
    productName: 'Cement Bags',
    quantity: '50,000',
    totalValue: '₦200,000,000'
  };

  const fieldLabels: Record<string, string> = {
    productID: 'Product ID',
    merchantName: 'Merchant Name',
    productName: 'Product Name',
    quantity: 'Quantity',
    totalValue: 'Total Value'
  };

  if (paymentSuccess) {
    return <PaymentSuccessComponent />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          py: 3,
          px: 2,
          maxWidth: 400,
          width: '100%',
          boxShadow: 3
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h7Bold" fontWeight="bold">
            Payment Summary
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            fontSize=".875rem"
            color="#667085"
          >
            Review your payment details for this product receipt before
            proceeding.
          </Typography>
        </Box>

        <Typography
          variant="bodyMediumSemibold"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: '#F7FCFA',
            padding: '0.5rem 1rem',
            borderRadius: '0.25rem',
            color: '#475467',
            fontSize: '0.875rem',
            fontWeight: 400,
            mt: 2,
            mb: 1
          }}
        >
          <SvgWrapper
            icon={InfoIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{ stroke: 'black', fill: 'none' }}
          />
          There’ll be no refund once payment is made
        </Typography>

        <Box sx={{ mb: 2 }}>
          {transactionDetails && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="bodyMediumSemibold"
                fontWeight="bold"
                sx={{ mb: 1 }}
              >
                PRC Details
              </Typography>
              <Divider
                sx={{
                  color: '#F5F6F7',
                  border: '.06rem solid',
                  mb: 2
                }}
              />
              {Object.entries(transactionDetails).map(([key, value]) => (
                <Grid container key={key} sx={{ py: 1 }}>
                  <Grid
                    item
                    xs={6}
                    sx={{ color: 'gray', textTransform: 'capitalize' }}
                  >
                    {fieldLabels[key] || key}{' '}
                  </Grid>
                  <Grid item xs={6} textAlign="right" fontWeight="medium">
                    {value}
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            backgroundColor: 'transparent',
            border: '0.125rem solid #F5F6F7',
            mb: 2
          }}
        >
          <Box
            sx={{
              height: '9.75rem',
              background: 'rgba(250, 251, 252, 0.99)',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            {[
              { label: 'TOTAL VALUE', value: '₦ 2,000,000' },
              { label: 'FLUX FEE', value: '₦ 2,000' },
              { label: 'GRAND TOTAL', value: ' ₦2,200,000', bold: true }
            ].map(({ label, value, bold }, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 2.5,
                  py: 2
                }}
              >
                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    color: '#667085'
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    color: '#475467',
                    fontWeight: bold ? 'bold' : 'normal'
                  }}
                >
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <FormControlLabel
          control={<Checkbox />}
          label="By proceeding, you authorize the payment to be processed securely via PayStack."
          sx={{ mt: 1, mb: 2, color: '#475467', fontSize: '.875rem' }}
        />

        <Box
          sx={{
            px: 2,
            py: 1.5,
            backgroundColor: 'white',
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 1.5
          }}
        >
          <Button
            size="md"
            text="Proceed to Paystack"
            color="primary"
            styleOverrides={{
              width: '100%',
              background: 'linear-gradient(180deg, #3E7DF8 0%, #3974E7 100%)',
              borderRadius: 2,
              border: 'none'
            }}
            onClick={() => setPaymentSuccess(true)}
          />
          <Button
            outline
            size="md"
            text="Cancel Payment"
            color="primary"
            styleOverrides={{
              width: '100%',
              color: '#475467',
              borderRadius: 2,
              borderColor: '#F2F4F7'
            }}
            onClick={() => navigate(-1)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSummaryPage;
