import React, { useContext, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import {
  CreateProductReceiptContext,
  ProductReceiptIssueType
} from '../../context/createProductReceiptContext';
import _ from 'lodash';
import CreateProductReceiptBottomNav from '../../nav/bottomNav';
import { ProductApiData } from 'services/types/product';
import { Button } from 'components/button';

type ReviewBatchPRCProps = {
  productData: ProductApiData[] | undefined;
};

/**
 * ReviewBatchProductReceipts
 *
 * This component is responsible for reviewing the batch product receipts
 * before submission. It displays:
 *  - Common details (customer, bank (if required), product, product option, unit, price)
 *  - A tags section showing the actual tags (tag type and tag name) added by the user.
 *  - A section for each batch receipt entry (quantity and expiry date)
 *  - Pagination controls to navigate between batch entries
 *  - A bottom navigation that triggers the submission if the form is complete.
 */
const ReviewBatchProductReceipts = ({ productData }: ReviewBatchPRCProps) => {
  // Extract common data and selected issue type from context.
  const { data, selectedIssueType } = useContext(CreateProductReceiptContext);

  // Local state to handle pagination for batch receipt entries.
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Ensure that quantity and expiry_date are arrays.
  const batchQuantities: string[] = Array.isArray(data.quantity)
    ? data.quantity
    : [data.quantity];
  const batchExpiryDates: string[] = Array.isArray(data.expiry_date)
    ? data.expiry_date
    : [data.expiry_date];

  // Build a list of batch receipt entries for display.
  const batchReceipts = batchQuantities.map((q: string, index: number) => ({
    quantity: q,
    expiry_date: batchExpiryDates[index] || ''
  }));

  // Helper: Get product name from product ID.
  const productName = (productId: string): string => {
    const product = productData?.find(item => item.product_id === productId);
    return product ? product.name : '---';
  };

  // Helper: Get product variant detail (e.g. currency) from product and variant IDs.
  const productVariantName = (productId: string, variantId: string): string => {
    const product = productData?.find(item => item.product_id === productId);
    if (product && product.variants) {
      const variant = product.variants.find(
        v => v.product_variant_id === variantId
      );
      return variant ? variant.currency : '---';
    }
    return '---';
  };

  // Compute a completeness flag.
  // The form is complete if:
  // - All common fields (customer, product, product variant, unit, amount) are filled.
  // - If the issue type is INTERMEDIARY, the bank field is required.
  // - Every batch receipt entry has a non-empty quantity and expiry date.
  // - At least one tag exists (the tags array must have a length of at least 1).
  const formIsComplete =
    Boolean(data.customer_name) &&
    (selectedIssueType === ProductReceiptIssueType.DIRECT ||
      Boolean(data.bank)) &&
    Boolean(data.product) &&
    Boolean(data.product_variant) &&
    Boolean(data.unit) &&
    Boolean(data.amount) &&
    Array.isArray(batchQuantities) &&
    batchQuantities.every(q => Boolean(q)) &&
    Array.isArray(batchExpiryDates) &&
    batchExpiryDates.every(date => Boolean(date)) &&
    Array.isArray(data.tags) &&
    data.tags.length >= 1;

  return (
    <>
      {/* Common Details Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '29rem',
          margin: '0 auto',
          mt: '1.5rem',
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          mb: 3,
          p: '1rem'
        }}
      >
        {/* Header for common details */}
        <Stack
          spacing={1}
          sx={{ borderBottom: '0.063rem solid #F5F6F7', pb: '1rem' }}
        >
          <Typography variant="h6Bold" color="#475467">
            Review Common Details
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Confirm that the common details are correct.
          </Typography>
        </Stack>
        {/* Display common fields */}
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Customer
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.startCase(data.customer_name)}
            </Typography>
          </Stack>
          {selectedIssueType === ProductReceiptIssueType.INTERMEDIARY && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="bodyMediumMedium" color="#667085">
                Bank
              </Typography>
              <Typography variant="bodyLargeSemibold" color="#475467">
                {_.startCase(data.bank)}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Product
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.startCase(productName(data.product))}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Product Option
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {_.startCase(
                `${productName(data.product)} ${productVariantName(data.product, data.product_variant)}`
              )}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Unit
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {data.unit || '---'}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Price
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {formattedNumber(data.amount, true) || '₦0'}
            </Typography>
          </Stack>
          {/* Tags Section: Display actual tags from context */}
          {data.tags && data.tags.length > 0 && (
            <Box
              sx={{
                mt: 1,
                p: 1,
                border: '0.063rem solid #E0E0E0',
                borderRadius: '0.5rem',
                backgroundColor: '#FAFAFA'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#333">
                Tags:
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                {data.tags.map((tag, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      backgroundColor: '#F5F6F7',
                      borderRadius: '0.375rem',
                      px: 1,
                      py: 0.5
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#3A76F0">
                      {`${tag.tagsType}: ${tag.tagsName}`}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>

      {/* Batch Receipt Entries Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '29rem',
          margin: '0 auto',
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          mb: '3rem',
          p: '1rem'
        }}
      >
        <Typography variant="h6Bold" color="#475467" sx={{ mb: '1rem' }}>
          Batch Receipt Details
        </Typography>
        {/* Display details for the current batch receipt */}
        <Box
          sx={{
            borderRadius: '0.5rem',
            border: '0.0625rem solid #F5F6F7',
            background: 'rgba(102, 112, 133, 0.02)',
            p: '0.75rem',
            mb: '1rem'
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Quantity
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {formattedNumber(batchReceipts[currentIndex].quantity) || '---'}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyMediumMedium" color="#667085">
              Expiry Date
            </Typography>
            <Typography variant="bodyLargeSemibold" color="#475467">
              {formattedDate(
                batchReceipts[currentIndex].expiry_date,
                'Do MMM, YYYY'
              ) || '---'}
            </Typography>
          </Stack>
        </Box>

        {/* Pagination Controls to navigate between batch receipt entries */}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ py: '0.75rem', borderTop: '0.063rem solid #F5F6F7' }}
        >
          <Typography variant="bodyMediumSemibold" color="#98A2B3" mr="1rem">
            {currentIndex + 1}/{batchReceipts.length}
          </Typography>
          <Box display="flex" gap="1rem">
            {currentIndex !== 0 && (
              <>
                <Button
                  color={'primary'}
                  outline
                  size="md"
                  text="Back"
                  width="100%"
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                />
              </>
            )}
            {currentIndex < batchReceipts.length - 1 && (
              <Button
                color={'primary'}
                outline
                size="md"
                text=" Next"
                icon={ArrowLeft}
                iconPosition="end"
                width="100%"
                onClick={() => setCurrentIndex(currentIndex + 1)}
              />
            )}
          </Box>
        </Stack>
      </Box>

      {/* Bottom Navigation (submission button) */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <CreateProductReceiptBottomNav
          nextButtonLabel="Submit"
          isNextDisabled={!formIsComplete}
        />
      </Box>
    </>
  );
};

export default ReviewBatchProductReceipts;
