import { Avatar, Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { Table, useServerSidePagination } from 'components/table';

import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Chip } from 'components/chip';
import { useNavigate } from 'react-router-dom';
import {
  useLazyGetPickupRequestsQuery,
  usePrefetch
} from 'services/api/productReceiptApi';
import { ProductPickupRequestApiData } from 'services/types/productReceipt';
import _ from 'lodash';

const emptyPickupRequestData: ProductPickupRequestApiData[] = [];

const columnHelper = createColumnHelper<ProductPickupRequestApiData>();
const columns = [
  columnHelper.accessor('product_receipt_id', {
    header: 'PRC CODE',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#475467">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('customer', {
    header: 'customer',
    cell: info => (
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar
          src={info.getValue().logo}
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            mr: '0.75rem',
            justifyContent: 'center',
            borderRadius: '12.5rem'
          }}
        />
        <Stack display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="bodyMediumMedium" color="#344054">
            {info.getValue().name}
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            {info.getValue().organization_id}
          </Typography>
        </Stack>
      </Stack>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('product', {
    header: 'product',
    cell: info => (
      <Typography
        variant="bodyMediumMedium"
        color="#344054"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('depot', {
    header: 'depot',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#344054">
        {info.getValue()?.branch_address || '---'}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('scheduled_on', {
    header: 'status',
    cell: info =>
      chipData.map((chip, index) => {
        const status = _.isNull(info.getValue())
          ? 'Not Scheduled'
          : 'Scheduled';
        return (
          <Box key={index}>
            {status === chip.text && (
              <Chip label={status} size="sm" color={chip.color} icon />
            )}
          </Box>
        );
      }),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<ProductPickupRequestApiData>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const PickupRequestTable = () => {
  const navigate = useNavigate();
  const [fetchPickupRequests, { isLoading, isFetching }] =
    useLazyGetPickupRequestsQuery();
  const prefetchPickupRequests = usePrefetch('getPickupRequests');

  const fetchPaginatedPickupRequests = (
    pageNumber: number
  ): Promise<{ results: ProductPickupRequestApiData[]; count: number }> => {
    return fetchPickupRequests({
      params: {
        page: pageNumber.toString(),
        pageSize: '10'
      }
    })
      .unwrap()
      .then(result => {
        return { results: result.results, count: result.count };
      })
      .catch(error => {
        console.log(error);
        return { results: [], count: 0 };
      });
  };

  const prefetchPaginatedPickupRequests = (pageNumber: number) => {
    prefetchPickupRequests({
      params: {
        page: pageNumber.toString(),
        pageSize: '10'
      }
    });
  };

  const { data, rowCount, pagination, prefetchPages, getPage } =
    useServerSidePagination({
      pageSize: 10,
      getServerPage: fetchPaginatedPickupRequests,
      prefetchServerPage: prefetchPaginatedPickupRequests
    });

  const pickupRequestData = useMemo(() => {
    if (data) {
      return data;
    }
    return emptyPickupRequestData;
  }, [data]);

  return (
    <Table<ProductPickupRequestApiData>
      hover
      columns={columns}
      data={pickupRequestData}
      onClickRow={(rowData: ProductPickupRequestApiData) => {
        navigate(`/product-receipts/${rowData.product_receipt_id}/pickup`);
      }}
      optionsButton
      enableFilter={false}
      filterTabs={['product', 'customer', 'scheduled_on']}
      filterColumns={['status']}
      searchPlaceholderText="Search with status"
      loadingTable={isLoading || isFetching}
      serverSidePagination={pagination}
      serverSideRowCount={rowCount}
      prefetchPages={prefetchPages}
      getPage={getPage}
    />
  );
};

export default PickupRequestTable;

const chipData: { text: string; color: 'default' | 'success' }[] = [
  {
    text: 'Not Scheduled',
    color: 'default'
  },
  {
    text: 'Scheduled',
    color: 'success'
  }
];
