import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Tab as MUITab,
  Tabs as MUITabs,
  Stack
} from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as Safe } from 'assets/svg/safe.svg';
import { Button } from 'components/button';
import SearchableContainer from 'components/search/searchableContainer';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { useAppSelector } from 'services/hook';
import { useGetVaultMetaDataQuery } from 'services/api/vaultApi';
import { OrganizationType } from 'services/enums/organization';
import { BranchData, BranchVaultData } from 'services/types/vault';
import { OrganizationApiData } from 'services/types/organization';
import VaultSubOrgCard from './cards/vaultSubOrgCard';
import MainOrgCard from './cards/vaultMainOrgCard';
import _ from 'lodash';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { PermissionType } from 'services/enums/permissions';

import { vaultPageText } from 'copy/vault';

const tabList = ['central vault'];

const ProductReceiptVaultPage = () => {
  const [currentSubOrgView, setCurrentSubOrgView] = useState(0);
  const [page, setPage] = useState(0);
  const [currentSubOrgIndex, setCurrentSubOrgIndex] = useState(0);
  const [currentSubOrgData, setCurrentSubOrgData] =
    useState<BranchVaultData | null>(null);

  const { organizationType } = useAppSelector(state => state.auth);
  const { FEATURE_FLAG_ENABLE_BRANCH_VAULT } = useAppSelector(
    state => state.featureFlags
  );

  const { data: permissions } = useGetPermissionsQuery({});
  const {
    data: vaultMetaData,
    isLoading,
    isFetching
  } = useGetVaultMetaDataQuery({});

  const navigate = useNavigate();

  const centralVaultData = useMemo(() => {
    const emptyVaultData: OrganizationApiData[] = [];
    if (vaultMetaData) {
      return vaultMetaData.central_vault;
    }
    return emptyVaultData;
  }, [vaultMetaData]);

  const branchVaultData = useMemo(() => {
    const emptyVaultData: BranchVaultData[] = [];
    if (vaultMetaData) {
      return vaultMetaData.branch_vault;
    }
    return emptyVaultData;
  }, [vaultMetaData]);

  FEATURE_FLAG_ENABLE_BRANCH_VAULT && tabList.push('branch vault');

  const branchData = branchVaultData[currentSubOrgIndex]?.branches;

  const renderCentralVaultData = (item: OrganizationApiData) => {
    return (
      <MainOrgCard
        id={item.organization_id}
        name={item.name}
        logo={item.logo}
        onClick={() => {
          navigate(`/product-receipts/central-vault/${item.organization_id}`);
        }}
      />
    );
  };

  const renderBranchVaultData = (item: BranchVaultData, index?: number) => {
    return (
      <VaultSubOrgCard
        id={item.organization.organization_id}
        name={_.startCase(item.organization.name)}
        onClick={() => {
          setCurrentSubOrgData(item);
          index !== undefined ? setCurrentSubOrgIndex(index) : null;
          switchToNextPage();
        }}
      />
    );
  };

  const renderBranchesVaultData = (item: BranchData) => {
    return (
      <VaultSubOrgCard
        id={item.name}
        name={_.startCase(item.name)}
        onClick={() =>
          navigate(
            `/product-receipts/branch-vault/${currentSubOrgData?.organization?.organization_id}/${item.branch_id}`
          )
        }
      />
    );
  };

  const switchToNextPage = () => {
    setPage(prev => prev + 1);
  };

  const switchToPrevPage = () => {
    setPage(prev => prev - 1);
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentSubOrgView(newTabIndex);
  };

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentSubOrgView];
  };

  switch (page) {
    case 0:
      return (
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              padding: '2rem',
              backgroundColor: '#ffffff',
              width: '100%',
              top: '0',
              left: '0',
              zIndex: 2,
              position: 'absolute'
            }}
          >
            <Box width="100%" display="flex" justifyContent="space-between">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Stack gap=".25rem" width="inherit">
                  <Typography
                    variant="h6Bold"
                    color="#475467"
                    sx={{ width: 'auto' }}
                  >
                    {vaultPageText[organizationType]?.pageTitle}
                  </Typography>
                  <Typography
                    variant="bodyMediumMedium"
                    color="#98A2B3"
                    sx={{ width: 'auto' }}
                  >
                    {vaultPageText[organizationType]?.pageSubtitle}
                  </Typography>
                </Stack>
              </Box>
              {organizationType !== OrganizationType.DISTRIBUTOR && (
                <Box sx={{ minWidth: '8rem' }}>
                  {permissions &&
                    permissions?.includes(
                      PermissionType.PRODUCT_RECEIPT_UPDATE
                    ) && (
                      <Button
                        color="primary"
                        size="md"
                        icon={plusIcon}
                        iconPosition="start"
                        text={
                          organizationType === OrganizationType.MERCHANT
                            ? 'Create Product Receipt'
                            : 'Request Product Receipt'
                        }
                        onClick={() => {
                          navigate('/product-receipts/create/?requestId=null');
                        }}
                      />
                    )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              mt: {
                md: '4rem',
                xl: '6rem'
              },
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                width: '100%',
                top: '6rem',
                left: '0',
                zIndex: 2,
                pl: '1.25rem',
                backgroundColor: 'white',
                position: 'absolute'
              }}
            >
              <MUITabs
                orientation="horizontal"
                value={currentSubOrgView}
                onChange={(e, value) => handleChange(value)}
                sx={{
                  borderBottom: 1,
                  borderColor: '#F5F6F7',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <MUITab
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5rem',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}
                    >
                      <SvgWrapper
                        icon={Safe}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: isActiveTab('central vault')
                            ? '#3E7DF8'
                            : '#D0D5DD'
                        }}
                      />
                      <Typography variant="bodyMediumMedium">
                        {FEATURE_FLAG_ENABLE_BRANCH_VAULT
                          ? 'Central Vault'
                          : 'Vault'}
                      </Typography>
                    </Box>
                  }
                />
                {FEATURE_FLAG_ENABLE_BRANCH_VAULT && (
                  <MUITab
                    label={
                      <Typography variant="bodyMediumMedium">
                        Branch Vault
                      </Typography>
                    }
                  />
                )}
              </MUITabs>
            </Box>
            <Box
              role="tabpanel"
              hidden={!isActiveTab('central vault')}
              sx={{ width: '100%' }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: '1.25rem',
                  borderRadius: '0.75rem',
                  backgroundColor: '#FFF',
                  position: 'relative',
                  height: '100%',
                  border: '.0625rem solid #F5F6F7'
                }}
              >
                <SearchableContainer<OrganizationApiData>
                  searchPlaceholderText={
                    vaultPageText[organizationType]?.searchPlaceholder ?? ''
                  }
                  data={centralVaultData}
                  renderDataList={renderCentralVaultData}
                  searchField={['name']}
                  containerStyles={{
                    maxHeight: '60svh',
                    overflowY: 'auto',
                    width: '100%',
                    borderTop: 1,
                    borderColor: '#F5F6F7'
                  }}
                  listStylesOverride={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: {
                      md: '2rem 1.25rem',
                      xl: '2rem'
                    },
                    padding: {
                      md: '1.25rem 0.75rem',
                      xl: '2rem 2.5rem'
                    },
                    borderRadius: '0.75rem',
                    backgroundColor: '#FCFCFD',
                    mt: '1.5rem'
                  }}
                  loading={isFetching || isLoading}
                  enableEmptyData
                  headerButtonPosition="end"
                  headerButton={
                    <Stack direction="row">
                      <Button
                        color={'grey'}
                        text={'Filter'}
                        transparent
                        icon={ChevronDown}
                        iconPosition="end"
                        size="sm"
                      />
                    </Stack>
                  }
                />
              </Paper>
            </Box>
            <Box
              role="tabpanel"
              hidden={!isActiveTab('branch vault')}
              id="simple-tabpanel-pickup"
              aria-labelledby="simple-tab-pickup"
              sx={{ width: '100%' }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: '1.25rem',
                  borderRadius: '0.75rem',
                  backgroundColor: '#FFF',
                  position: 'relative',
                  height: '100%',
                  border: '.0625rem solid #F5F6F7'
                }}
              >
                <SearchableContainer<BranchVaultData>
                  searchPlaceholderText={
                    vaultPageText[organizationType]?.searchPlaceholder ?? ''
                  }
                  data={branchVaultData}
                  renderDataList={renderBranchVaultData}
                  searchField={[
                    'organization.name',
                    'organization.organization_id'
                  ]}
                  containerStyles={{
                    maxHeight: '60svh',
                    overflowY: 'auto',
                    width: '100%',
                    borderTop: 1,
                    borderColor: '#F5F6F7'
                  }}
                  listStylesOverride={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: {
                      md: '2rem 1.25rem',
                      xl: '2rem'
                    },
                    padding: {
                      md: '1.25rem 0.75rem',
                      xl: '2rem 2.5rem'
                    },
                    borderRadius: '0.75rem',
                    backgroundColor: '#FCFCFD',
                    mt: '1.5rem'
                  }}
                  title={'Branch vault'}
                  subtitle={
                    vaultPageText[organizationType]?.branchVaultSubtitle
                  }
                  enableEmptyData
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      );
    case 1:
      return (
        <Box sx={{ height: '100%' }}>
          <Box mb="1rem">
            <Button
              color="grey"
              transparent
              icon={ChevronLeft}
              iconPosition="start"
              size="sm"
              onClick={() => switchToPrevPage()}
              text="Back to Branch Vault"
              width="auto"
              styleOverrides={{
                border: 'none'
              }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Paper
              elevation={0}
              sx={{
                padding: '1.25rem',
                borderRadius: '0.75rem',
                backgroundColor: '#FFF',
                position: 'relative',
                height: '100%',
                border: '.0625rem solid #F5F6F7'
              }}
            >
              <SearchableContainer<BranchData>
                searchPlaceholderText="Search with branch name"
                data={branchData}
                renderDataList={renderBranchesVaultData}
                searchField={['name']}
                containerStyles={{
                  maxHeight: '60svh',
                  overflowY: 'auto',
                  width: '100%',
                  borderTop: 1,
                  borderColor: '#F5F6F7'
                }}
                listStylesOverride={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: {
                    md: '2rem 1.25rem',
                    xl: '2rem'
                  },
                  padding: {
                    md: '1.25rem 0.75rem',
                    xl: '2rem 2.5rem'
                  },
                  borderRadius: '0.75rem',
                  backgroundColor: '#FCFCFD',
                  mt: '1.5rem'
                }}
                title={_.startCase(currentSubOrgData?.organization?.name)}
                subtitle={vaultPageText[organizationType]?.branchesViewSubtitle}
                enableEmptyData
              />
            </Paper>
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

export default ProductReceiptVaultPage;
