import React, { useContext, useEffect } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import fileupload from 'assets/svg/fileupload.svg';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { FormFileInput, FormInput } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import FileView from 'components/common/fileView';
import { theme } from 'themes/theme';
import { shadowsTheme } from 'themes/shadows';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { PickupRequestContext } from '../pickupRequestContext';
import { vehicleInformationFormValidator } from '../../validator/pickupValidators';

type FormValues = {
  vehicle_photo: File | null;
  vehicle_make: string;
  vehicle_model: string;
  license: string;
  identifying_markers: string;
  vehicle_color: string;
};

const PickupRequestFormStep2 = () => {
  const {
    data,
    handlePrevStep,
    handleNextStep,
    uploadedFile,
    setUploadedFile,
    isLoading
  } = useContext(PickupRequestContext);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      vehicle_photo: data.vehicle_photo,
      vehicle_make: data.vehicle_make,
      vehicle_model: data.vehicle_model,
      vehicle_color: data.vehicle_color,
      license: data.license,
      identifying_markers: data.identifying_markers
    },
    resolver: yupResolver(
      vehicleInformationFormValidator
    ) as unknown as Resolver<FormValues>
  });

  const watchVehiclePhoto = watch('vehicle_photo', null);

  useEffect(() => {
    setUploadedFile(watchVehiclePhoto);
  }, [watchVehiclePhoto]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
  };

  return (
    <>
      <Stack
        display="flex"
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1.25rem 1.25rem 1rem 1.25rem',
          borderBottom: '.125rem solid #FCFCFD',
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem 0.75rem 0rem 0rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#F8FBFE',
            padding: '0.5rem',
            boxShadow: shadowsTheme.iconShadow,
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={Truck}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: 0.8,
              fill: '#C3D7FD'
            }}
          />
        </Box>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Pick Up Request
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Schedule pickup of your products using your product receipt
          </Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            padding: '1.25rem',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '4.5rem',
            borderRadius: '0.75rem',
            border: '.0625rem solid #FCFCFD',
            backgroundColor: '#F8FBFE',
            mt: '1.75rem',
            mb: '1.5rem'
          }}
        >
          {uploadedFile ? (
            <FileView
              preview={uploadedFile}
              removeFile={() => {
                setUploadedFile(null);
                setValue('vehicle_photo', null);
              }}
            />
          ) : (
            <Stack
              width="inherit"
              justifyContent="space-between"
              direction="row"
              gap="1rem"
              sx={{
                padding: '0.75rem 0rem'
              }}
            >
              <Stack alignItems="center" direction="row" gap=".5rem">
                <Avatar src={fileupload} />
                <Stack direction="column">
                  <Typography
                    variant="bodyMediumSemibold"
                    sx={{
                      color: `${theme.palette.common.heading}`
                    }}
                  >
                    Upload vehicle photo
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '10rem'
                    }}
                  >
                    <Typography
                      variant="bodyMediumRegular"
                      color={theme.palette.common.textColor}
                    >
                      JPG format
                    </Typography>
                    <Box component="img" src={ellipse} />
                    <Typography
                      variant="bodyMediumRegular"
                      color={theme.palette.common.textColor}
                    >
                      Max. 5MB
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <FormFileInput<FormValues>
                control={control}
                name="vehicle_photo"
                label="Upload"
                setFile={(file: File | null) => setValue('vehicle_photo', file)}
                supportedFiles="image/jpg"
                fileInputButtonStyle={{
                  border: '.0625rem solid #0D5EBA',
                  borderRadius: '.5rem',
                  color: `${theme.palette.common.white}`,
                  backgroundColor: '#0D5EBA',
                  padding: '.5rem .75rem',
                  width: '5rem',
                  height: '2.25rem',
                  fontWeight: 600,
                  lineHeight: '1.26rem',
                  letterSpacing: '-0.01313rem',
                  '&:hover': {
                    border: '.0625rem solid #0D5EBA',
                    color: `${theme.palette.common.white}`,
                    backgroundColor: '#0D5EBA'
                  }
                }}
              />
            </Stack>
          )}
        </Box>

        <Typography
          variant="bodyMediumSemibold"
          color="#667085"
          sx={{
            paddingLeft: '1.25rem'
          }}
        >
          Vehicle Information
        </Typography>

        <Box
          sx={{
            padding: '1.25rem',
            paddingTop: '.75rem'
          }}
        >
          <FormInput<FormValues>
            control={control}
            name="vehicle_make"
            label="MAKE"
          />
          <FormInput<FormValues>
            control={control}
            name="vehicle_model"
            label="MODEL"
          />
          <FormInput<FormValues>
            control={control}
            name="vehicle_color"
            label="COLOR"
          />
          <FormInput<FormValues>
            control={control}
            name="license"
            label="LICENCE PLATE"
          />
          <FormInput<FormValues>
            control={control}
            name="identifying_markers"
            label="IDENTIFYING MARKERS (OPTIONAL)"
          />
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '.0625rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              handlePrevStep();
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Send Request"
            width="48%"
            loadingIcon={isLoading}
          />
        </Box>
      </form>
    </>
  );
};

export default PickupRequestFormStep2;
