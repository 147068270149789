import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';

type AnalyticsCardData = {
  title: string;
  value: string | number;
  currency?: boolean;
};

const DisplayCard = ({ title, value, currency }: AnalyticsCardData) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1rem 0rem',
        alignItems: 'center',
        backgroundColor: '#FFF',
        border: 'none'
      }}
    >
      <Stack
        direction="row"
        gap=".5rem"
        sx={{
          height: '100%',
          width: 'auto'
        }}
      >
        <Stack
          direction="column"
          display="flex"
          justifyContent="space-between"
          sx={{
            height: '100%'
          }}
        >
          <Stack
            direction="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              gap: {
                md: '1rem',
                xl: '1.25rem'
              }
            }}
          >
            <Typography variant="bodyMediumMedium" color="#667085">
              {title}
            </Typography>
          </Stack>

          <Box sx={{ display: 'flex', mt: 1 }}>
            <Typography variant="h7Semibold" color="#475467">
              {currency && '₦'} {value}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default DisplayCard;
